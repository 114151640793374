<script>
  import { UserList } from '$lib/components'

  /** @type {import('$lib/types').Contact[]} */
  export let additional_guests = []

  /** @type {import('$lib/types').Event} */
  export let event

  $: max_additional_guests = event.attendance_limit ? Math.min(event.max_additional_guests, event.attendance_limit - event.attendee_stats.going - 1) : event.max_additional_guests
</script>

{#if additional_guests.length}
  <div class="mt-3">
    <h3>Bringing { additional_guests.length === 1 ? 'this additional guest' : 'these additional guests' }:</h3>
    <UserList users={ additional_guests } class="mt-2 text-sm" />
  </div>
{/if}

<label for="attendance-additional-guests" class="mt-3 btn w-full">
  { additional_guests.length ? 'Change' : (max_additional_guests === 1 ? 'Bring an additional guest' : 'Bring additional guests') } &rarr;
</label>
