/**
 * @param {import('$lib/types').Attendance} attendance
 * @param {{ short?: boolean; is_past?: boolean; article?: 'You' | 'They' }} options
 * @returns {string}
 */
export const attendance_label = (attendance, options = {}) => {
  const article = options.article || 'You'

  const labels = {
    invited: {
      past: {
        short: 'Invited',
        long: `${article} were invited!`
      },
      future: {
        short: 'Invited',
        long: `${article}'re invited!`
      }
    },
    payment_required: {
      past: {
        short: 'Invited',
        long: `${article} were invited!`
      },
      future: {
        short: 'Invited',
        long: `${article}'re invited!`
      }
    },
    going: {
      past: {
        short: `${article} went`,
        long: `${article} went!`
      },
      future: {
        short: `Going`,
        long: `${article}'re going!`
      }
    },
    cannot_go: {
      past: {
        short: `Didn't go`,
        long: `${article} didn't go.`
      },
      future: {
        short: `Not going`,
        long: `${article}'re not going.`
      }
    },
    maybe: {
      past: {
        short: `Maybe went`,
        long: `${article} maybe went.`
      },
      future: {
        short: `Maybe`,
        long: `${article}'re ${parseFloat(attendance.probability?.toFixed(2))}% likely to go.`
      }
    }
  }

  return labels[attendance.status][options.is_past ? 'past' : 'future'][
    options.short ? 'short' : 'long'
  ]
}

export { attendance_label as attendanceLabel }
