<script>
  import { inertia } from '@inertiajs/svelte'
  import { format } from 'date-fns'

  let { posts, total, limit, page } = $props()

  let total_pages = $derived(Math.ceil(total / limit))

  let min = $derived(Math.max(1, page - 2)),
      max = $derived(Math.min(total_pages, page + 2))
</script>

<svelte:head>
  <title>Occasionly updates</title>
</svelte:head>

<div class="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-8">
  <h1 class="break-words text-3xl font-bold md:text-4xl">Occasionly updates</h1>

  <main>
    {#each posts as post}
      <div class="mt-12 md:mt-20 space-y-6">
        {#if post.image}
          <div class="aspect-[16/9]">
            <img src={post.image} class="h-full w-full object-cover rounded-box" alt={post.title} />
          </div>
        {/if}

        <div class="relative">
          <div
            class="left-0 top-0 text-base text-gray-400 md:absolute md:mt-1 md:-ml-5 md:-translate-x-full"
          >
            {format(new Date(post.date), 'do MMM yyyy')}
          </div>

          <a
            use:inertia
            href="/updates/{post.slug}"
            class="decoration-transparent decoration-2 underline-offset-4 transition-[text-decoration] duration-300 hover:decoration-black"
            ><h2 class="mt-1 text-3xl font-bold">{post.title}</h2></a
          >
        </div>
      </div>

      <article class="prose mt-6">
        {@html post.html}
      </article>
    {/each}
  </main>

  <div class="join mt-6 flex justify-center">
    {#if page > 1}
      <a use:inertia aria-label="Previous" href="/updates?page=1" class="join-item btn">
        <span class="i-feather-chevrons-left"></span>
      </a>
    {/if}

    {#each Array.from({ length: max - min + 1 }).map((_, i) => i + min) as page_number }
      <a use:inertia href="/updates?page={page_number}" class="join-item btn" class:btn-active={page_number === page}>
        {page_number}
      </a>
    {/each}

    {#if page < total_pages}
      <a use:inertia aria-label="Next" href="/updates?page={total_pages}" class="join-item btn">
        <span class="i-feather-chevrons-right"></span>
      </a>
    {/if}
  </div>
</div>
