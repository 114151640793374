<script>
  import { page } from '@inertiajs/svelte'

  import { WhatsAppMarkdown } from '$lib/components'
  import { format_datetime } from '$lib/utilities'

  let { event: current_event, current_user } = $derived($page.props)

  let { event = current_event, as_hosts = false, invite_count = 1, class: class_name = '' } = $props()

  const whatsapp_channel = current_user?.notification_channels.find(({ name }) => name === 'whatsapp')

  const inviter_message = whatsapp_channel ? `\n\n*Message ${current_user?.name.split(' ')[0] }*: ${whatsapp_channel.identifier}` : ''

  /** @param {import('$lib/types').Event} event */
  const format_start_time = (event) => {
    const time_format = event.is_all_day ? "'on' eeee do MMMM yyyy zzz" : "'at' HH':'mm 'on' eeee do MMMM yyyy zzz"

    return format_datetime(new Date(event.start_time), time_format)
  }

  /** @param {import('$lib/types').Event} event */
  const format_ticket_price = (event) => (event.ticket_price / 100).toFixed(event.ticket_price % 100 == 0 ? 0 : 2)

  /** @param {import('$lib/types').Event} event */
  const format_location = (event) => `at ${ event.visible_location || event.location.formatted_address }`

  let get_text = $derived(() => {
    if (as_hosts)
      return `${current_user?.name} has invited you to host *${event.title}* ${format_start_time(event)} ${format_location(event)}.\n\nTo see details and respond: ${event.url}${inviter_message}`

    if (event.ticket_price)
      return `${current_user?.name} has invited you to *${event.title}* ${format_start_time(event)} ${format_location(event)}.\n\nTickets are £${format_ticket_price(event)}, and you can buy them here: ${event.url}${inviter_message}`


    return `${current_user?.name} has invited you to *${event.title}* ${format_start_time(event)} ${format_location(event)}.\n\nTo see details and RSVP: ${event.url}${inviter_message}`
  })
</script>

<div class="{class_name}">
  <p>Here's what the invitation{invite_count === 1 ? '' : 's'} will look like:</p>

  <WhatsAppMarkdown class="whitespace-pre-line text-sm border-l-2 border-l-boundary pl-2 mt-2" renderLinks text={get_text()} />
</div>
