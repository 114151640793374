<script>
  import { onMount } from 'svelte'
  import { page } from '@inertiajs/svelte'
  import { Modal, Errors, Form } from '$lib/components'

  let modal_open = $state(false)

  onMount(() => {
    let url = new URL($page.props.url)

    if (url.searchParams.has('login')) {
      setTimeout(() => modal_open = true, 300)

      url.searchParams.delete('login')

      window.history.replaceState(null, '', url.toString())
    }
  })
</script>

<Modal class="text-left !z-50" bind:open={modal_open} id="login-modal">
  <h3 class="text-2xl font-medium">Sign in to Occasionly</h3>

  <p class="mt-1 font-normal">Enter your email, WhatsApp, Signal or Telegram number below and we'll send you a link to sign in.</p>

  <Form
    action="/auth"
    onsuccess={() => modal_open = false}
  >
    <input
      type="text"
      required
      autocomplete="email"
      class="input input-bordered w-full mt-3"
      placeholder="you@example.com"
      name="identifier"
    />

    <Errors errors={$page.props.errors?.identifier} />

    <button type="submit" class="btn btn-primary w-full text-base mt-3">
      Send me the link
    </button>
  </Form>
</Modal>
