<script>
  import { onMount } from 'svelte'
  import { page } from '@inertiajs/svelte'

  import { Modal, Form, Errors, SlideTransition } from '$lib/components'

  let restProps = $props()

  /** @type {import('$types').PageProps<'event' | 'current_user'>} */
  let { event, current_user } = $derived($page.props)

  /** @type {string} */
  let id_or_url = $state('')

  /** @type {boolean} */
  let modal_open = $state(false)

  onMount(() => {
    let url = new URL($page.props.url)

    if (url.searchParams.has('export')) {
      setTimeout(() => modal_open = true, 300)

      url.searchParams.delete('export')

      window.history.replaceState(null, '', url.toString())
    }
  })
</script>

<Modal {...restProps} bind:open={modal_open}>
  <h3 class="text-2xl line-clamp-1">Export <strong>{ event.title }</strong></h3>

  {#if current_user.is_google_authenticated}
    <SlideTransition key={event.google_sheet_connection?.id}>
      {#if event.google_sheet_connection}
        <div class="flex items-start mt-3 space-x-2">
          <span class="i-feather-check-circle h-4 w-4 mt-1 text-emerald-500"></span>

          <p>Syncing this event with <a href="https://docs.google.com/spreadsheets/d/{event.google_sheet_connection.spreadsheet_id}" class="text-emerald-600 hover:underline inline-flex items-center gap-1" target="_blank">your Google Sheet <span class="i-feather-external-link h-4 w-4"></span></a>.</p>
        </div>

        <Form action="/google/sheet_connections/{event.google_sheet_connection.id}" method="delete">
          <button type="submit" class="mt-3 w-full btn btn-error">
            Stop syncing
          </button>
        </Form>
      {:else}
        <p class="mt-2">Sync with a Google Sheet by entering its ID or URL below, or create a new one.</p>

        <p class="mt-3">We'll use the "Attendees" worksheet or create a new one, overwriting any existing data.</p>

        <Form action="/google/sheet_connections" onsuccess={() => id_or_url = ''}>
          <input type="hidden" name="event_id" value={event.id} />

          <div class="mt-3 join w-full">
            <input
              class="input input-bordered w-full join-item"
              placeholder="Google Sheet ID or URL"
              name="id_or_url"
              bind:value={id_or_url}
            />

            <button
              type="submit"
              class="btn btn-primary text-base join-item"
              disabled={!id_or_url}
            >Sync</button>
          </div>

          <Errors errors={$page.props.errors?.spreadsheet_id} />

          <button
            type="submit"
            name="id_or_url"
            value=""
            class="w-full mt-3 btn btn-primary text-base"
          >Create new Google Sheet</button>
        </Form>
      {/if}
    </SlideTransition>
  {:else}
    <p class="mt-2">
      Sign in with Google to sync the event attendees with a Google Sheet, or download a one-time spreadsheet below.
    </p>

    <p class="mt-3">
      We only use the Google Sheets you explicitly link, or that we create on your behalf.
    </p>

    <Form action="/google/auth">
      <button
        type="submit"
        class="w-full border-neutral-300 btn btn-outline mt-6 px-[8px] py-[11px]"
      >
        <div class="flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-[18px] w-[18px]" viewBox="0 0 186.69 190.5"><g transform="translate(1184.583 765.171)"><path clip-path="none" mask="none" d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z" fill="#4285f4"/><path clip-path="none" mask="none" d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z" fill="#34a853"/><path clip-path="none" mask="none" d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z" fill="#fbbc05"/><path d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z" fill="#ea4335" clip-path="none" mask="none"/></g></svg>

          <span class="pl-[24px] font-roboto font-normal text-base text-black opacity-[54%]">Sign in with Google</span>
        </div>
      </button>
    </Form>
  {/if}

  <div class="divider text-neutral/50">Or</div>

  <a
    href="/events/{event.id}.csv{$page.props.token ? `?auth_token=${ $page.props.token }` : ''}"
    download
    class="w-full btn btn-primary text-base"
  >
    Download CSV
  </a>
</Modal>

<style lang="postcss">
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Roboto:wght@500&display=swap');

  .font-roboto {
    font-family: 'Roboto', sans-serif;
  }
</style>
