import { get } from 'svelte/store'

import enGB from 'date-fns/locale/en-GB'
import { formatInTimeZone } from 'date-fns-tz'
import { time_zone } from '$lib/stores'

/**
 * @param {Date} date
 * @param {string} format
 * @param {string} [zone]
 */
export const format_datetime = (date, format, zone) =>
  formatInTimeZone(date, zone || get(time_zone), format, { locale: enGB })
