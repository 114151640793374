<script>
  import { onMount, tick, createEventDispatcher } from 'svelte'
  import { loadStripe } from '@stripe/stripe-js'
  import { page } from '@inertiajs/svelte'

  import { Form } from '$lib/components'

  import EventTipInput from './EventTipInput.svelte'
  import AdditionalGuestsButton from './AdditionalGuestsButton.svelte'

  const dispatch = createEventDispatcher()

  let { event, current_user, current_attendance } = $derived($page.props)

  let { additional_guests = [], ...restProps } = $props()

  /** @type {Form} */
  let form

  /** @type {import('@stripe/stripe-js').Stripe} */
  let stripe

  /** @type {import('@stripe/stripe-js').StripeElements} */
  let elements

  /** @type {HTMLInputElement} */
  let payment_method_id_el

  /** @type {boolean} */
  let loading = false

  /** @type {string | undefined} */
  let error_message = ''

  /** @type {string} */
  let tip_amount_value = ''

  let ticket_price = $derived(event.ticket_price * (additional_guests.length + 1))

  const calculate_tip_amount = () => {
    const tip_amount = Number(tip_amount_value) * 100

    return Math.max(Math.min(event.ticket_price, tip_amount), 0)
  }


  /** @returns {import('@stripe/stripe-js').StripeElementsOptionsMode} */
  const get_elements_options = () => ({
    mode: 'payment',
    amount: ticket_price + calculate_tip_amount(),
    currency: 'gbp',
    paymentMethodCreation: 'manual'
  })

  $effect(() => {
    if (elements && additional_guests) elements.update(get_elements_options())
  })

  const initialize = async () => {
    // @ts-ignore
    stripe = await loadStripe(env.PUBLIC_STRIPE_PUBLISHABLE_KEY)

    if (!stripe) return;

    elements = stripe.elements(get_elements_options())

    const paymentElement = elements.create('payment')
    paymentElement.mount('#payment-element')

    paymentElement.on('escape', () => paymentElement.blur())

    // paymentElement.on('ready', () => alert('ready!'))
  }

  /** @param {import('@stripe/stripe-js').StripeError} error */
  const handle_error = (error) => {
    loading = false

    error_message = error.message
  }

  const handle_success = () => {
    dispatch('success')

    if (current_attendance) {
      current_attendance.status = 'going'
    } else {
      /** @type {import('$lib/types').Attendance} */
      $page.props.current_attendance = {
        id: '',
        created_at: new Date().toISOString(),
        status:  'going',
        is_host: false,
        can_host: false,
        user_id: current_user?.id,
        event_id: event.id,
        probability: null
      }

      event.attendances = [...event.attendances, current_attendance]
    }

    event.attendee_stats.going += (1 + additional_guests.length)
  }

  /** @param {MouseEvent} evt */
  const handle_payment = async (evt) => {
    evt.preventDefault()

    loading = true
    error_message = ''

    const { error: submit_error } = await elements.submit()

    if (submit_error) {
      handle_error(submit_error)
      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements
    })

    if (error) {
      handle_error(error)
      return
    }

    payment_method_id_el.value = paymentMethod.id

    await tick()

    form.element().dispatchEvent(new SubmitEvent('submit'))
  }

  /**
   * @typedef {object} PaymentResponse
   * @property {string} client_secret
   * @property {string} [status]
   * @property {string} [error]
   */

  /** @param {PaymentResponse} response */
  const handle_response = async (response) => {
    if (response.error) {
      loading = false

      error_message = response.error
    } else if (response.status === "requires_action") {
      const {
        error
      } = await stripe.handleNextAction({
        clientSecret: response.client_secret
      })

      if (error) {
        handle_error(error)
      } else {
        handle_success()
      }
    } else {
      handle_success()
    }
  }

  onMount(initialize)
</script>

<Form
  bind:this={form}
  result={ ({ result }) => {
    if (result?.data) return handle_response(result.data)
  }}
  {...restProps}
>
  <h3 class="text-2xl">Buy a ticket to <strong>{ event.title }</strong>.</h3>

  {#if event.max_additional_guests}
    <AdditionalGuestsButton {additional_guests} event={event} />
  {/if}

  {#if event.tip_reason}
    <EventTipInput
      event={event}
      name="tip_amount"
      bind:value={tip_amount_value}
      on:input={() => {
        if (elements) { elements.update(get_elements_options()) }
      }}
    />
  {/if}

  <div id="payment-element" class="mt-3">
    <!-- Elements will create form elements here -->
  </div>

  <div
    class="max-h-0 pt-1 transition-[max-height] text-sm text-rose-600 overflow-hidden"
    class:max-h-24={!!error_message}
  >
    {error_message}
  </div>

  <p class="text-center text-xs text-gray-600 mt-2">By providing your card information, you agree to Occasionly's <a class="text-emerald-600 underline hover:no-underline" target="_blank" href="/terms">Terms of Service</a> and <a class="text-emerald-600 underline hover:no-underline" target="_blank" href="/privacy">Privacy Policy</a>.</p>

  <input type="hidden" bind:this={payment_method_id_el} name="payment_method_id" />

  {#each additional_guests as guest}
    <input type="hidden" name="additional_guest_ids[]" value={guest.id}>
  {/each}

  <button
    type="button"
    class="w-full btn btn-primary mt-3"
    on:click={handle_payment}
  >
    {#if loading}
      <span class="loading loading-spinner"></span>
    {/if}
    Buy ticket{ additional_guests.length ? 's' : ''} (£{ticket_price / 100})
  </button>
</Form>
