export const notifications = {
  /** @param {{ event: import('$lib/types').Event }} params */
  event_invite_attendance_limit_reached: ({ event }) => ({
    type: 'info',
    title: `You've reached the guest limit for this event (${event.attendance_limit}). To invite more, <a href="/events/${event.id}/edit?tab=attendees">update the attendance limit</a>.`
  }),

  not_spreadsheet_file: () => ({
    type: 'error',
    title: 'Please select a CSV or Excel file.'
  }),

  not_image_file: () => ({
    type: 'error',
    title: 'Please select an image.'
  })
}
