<script>
  /** @type {{
    actions?: import('svelte').Snippet
    photo: import('svelte').Snippet
    primary_details: import('svelte').Snippet
    secondary_details?: import('svelte').Snippet
    updates?: import('svelte').Snippet
    class?: string
  }} */
  let { actions, photo, primary_details, secondary_details, updates, class: class_name = '' } = $props()

  const gap = 'gap-y-3 md:gap-y-5'
</script>

<div class="relative h-full grid md:grid-cols-[5fr_6fr] gap-x-8 items-start z-0 {gap} {class_name}">
  {#if actions}
    <div class="md:col-span-2">
      {@render actions()}
    </div>
  {/if}

  <div class="row-span-3 md:-mt-1 grid {gap} max-md:contents">
    {@render photo()}

    {#if secondary_details}
      <div class="grid {gap} col-start-1 max-md:row-start-4">
        {@render secondary_details()}
      </div>
    {/if}
  </div>

  <div class="flex flex-col {gap} md:-mt-1">
    {@render primary_details()}
  </div>

  {#if updates}
    <div class="md:col-start-2">
      {@render updates()}
    </div>
  {/if}
</div>
