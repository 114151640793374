<script>
  import { on } from 'svelte/events'
  import ContactListItem from './ContactListItem.svelte'
  import SelectedContactListItems from './SelectedContactListItems.svelte'

  /** @type {{
    sections: import('$lib/types').ContactListSection[]
    selected_item_ids?: string[]
    query?: string
    empty_text?: string
    selectable?: boolean
    disabled?: boolean
    searchable?: boolean
    visible?: boolean
  }} */
  let {
    sections,
    selected_item_ids = $bindable([]),
    query = $bindable(''),
    empty_text = '',
    selectable = true,
    disabled = false,
    searchable = true,
    visible = false
  } = $props()

  let items = $derived(sections.reduce(
    /**
     * @param {import('$lib/types').ContactListItem[]} items
     * @param {import('$lib/types').ContactListSection} section
     */
    (items, section) => ([...items, ...section.items]), []).flat()
  )

  /** @type {number | undefined} */
  let list_height = $state()

  let filtered_sections = $derived(sections.reduce(
    /**
     * @param {import('$lib/types').ContactListSection[]} sections
     * @param {import('$lib/types').ContactListSection} section
     */
    (sections, section) => {
      let lower_query = query.toLowerCase()

      let items = section.items.filter(item => item.name.toLowerCase().includes(lower_query))

      if (items.length) {
        return [...sections, { ...section, items }]
      }

      return sections
    }, [])
  )

  $effect(() => {
    if (visible) {
      search_input_el.focus()

      const cleanup = on(window, 'keydown', (evt) => {
        const is_input = ['INPUT', 'TEXTAREA'].includes(evt.target?.tagName) && !['checkbox', 'radio'].includes(evt.target?.type)

        if (!is_input && !(evt.ctrlKey || evt.metaKey)) {
          const key = evt.key.toLowerCase()

          switch (key) {
          case '/':
            evt.preventDefault()
            search_input_el.focus()

            break;
          }
        }
      })

      return () => cleanup()
    }
  })

  let search_input_el = $state()
  let is_search_input_focused = $state(false)
</script>

<div class="mt-3">
  {#if searchable}
    <label class="input input-bordered input-sm flex items-center gap-2 pr-1 mb-2.5">
      <input
        bind:this={search_input_el}
        class="grow"
        placeholder="Search..."
        autocomplete="off"
        onfocus={() => is_search_input_focused = true}
        onblur={() => is_search_input_focused = false}
        onkeydown={(evt) => {
          if (evt.key === 'Escape') search_input_el.blur()
         }}
        bind:value={query}
      />

      {#if !is_search_input_focused}
        <kbd class="kbd kbd-sm">/</kbd>
      {/if}
    </label>
  {/if}

  <div
    class="relative overflow-y-auto sm:max-h-80 md:max-h-96"
    bind:clientHeight={list_height}
    style="height: { list_height ? list_height + 'px' : 'auto' }; max-height: { list_height ? `calc(100vh - 412px)` : 'auto' };"
  >
    <SelectedContactListItems {items} bind:selected_item_ids />

    <div>
      <ul>
        {#each filtered_sections as section}
          {#if section.title}
            <h4
              class="mt-2 mb-1 rounded bg-base-200 !py-1.5 px-2 pb-0.5 text-xs font-medium uppercase leading-none tracking-wide text-base-content/70 first:mt-1"
            >
              {section.title}
            </h4>
          {/if}

          {#each section.items as item}
            <ContactListItem
              {item}
              bind:selected_item_ids
              search_query={query}
              {selectable}
              {disabled}
            >
              <slot {item} />
            </ContactListItem>
          {/each}
        {/each}
      </ul>
    </div>

    {#if empty_text && !filtered_sections.length }
      <div class="flex items-center justify-center px-2 py-3">
        <p class="text-center text-gray-500">{empty_text}</p>
      </div>
    {/if}
  </div>
</div>
