<script>
  import { getContext } from 'svelte'
  import { ContactList, Loadable, Pages, NewContactForm } from '$lib/components'
  import { group_array } from '$lib/utilities'

  const { active_page, initial_page } = getContext('pages')

  /** @type {import('$lib/types').Event} */
  export let event

  /** @type {import('$lib/types').Contact[]} */
  let contacts = []

  /** @type {string[]} */
  let selected_contact_ids = []

  /** @type {import('$lib/types').Contact[]} */
  export let additional_guests = []

  $: additional_guests = selected_contact_ids.map(id => contacts.find(contact => contact.id === id))

  $: max_additional_guests = event.attendance_limit ? Math.min(event.max_additional_guests, event.attendance_limit - event.attendee_stats.going - 1) : event.max_additional_guests

  /** @type {boolean} */
  let is_loading = true

  const fetch_contacts = async () => {
    // if (!contacts.length) is_loading = true

    // const result = await api.contacts.index({
    //   params: { event_id: event.id }
    // })

    // if (result.contacts) {
    //   contacts = result.contacts

    //   if (!contacts.length) $active_page = 'new-additional-guests-page'
    // }

    // is_loading = false
  }

  /** @param {any[]} guests */
  const button_label = (guests) => {
    if (guests.length === 0) return "Not bringing anyone"

    if (guests.length === 1) {
      const contact = contacts.find(({ id }) => id === selected_contact_ids[0])

      return `Bring ${contact?.name || '1 extra person'}`
    }

    return `Bring ${guests.length} extra people`
  }

  $: disabled = selected_contact_ids.length >= max_additional_guests

  const SECTION_ORDER = [
    'In your contacts',
    'Already going'
  ]

  /** @type {import('$lib/types').ContactListSection[]} */
  $: sections = Object.entries(group_array(contacts, ({ is_invited }) => {
    if (is_invited) return 'Already going'

    return 'In your contacts'
  }))
  .sort(([a_title], [b_title]) => SECTION_ORDER.indexOf(a_title) - SECTION_ORDER.indexOf(b_title))
  .map(([title, contacts]) => ({
    title,
    items: contacts.map(contact => ({
      ...contact,
      disabled: title === 'Already going' || disabled && !selected_contact_ids.includes(contact.id)
    }))
  }))
</script>

<Pages.Page id="attendance-additional-guests" on:open={fetch_contacts}>
  <h3 class="text-2xl mt-3">
    { max_additional_guests === 1 ? 'Bring an additional guest' : 'Bring additional guests' }
  </h3>

  <Loadable loading={is_loading}>
    {#if contacts.length}
      <div class="mt-2">
        {#if disabled}
          Select who you'd like to bring below (max {max_additional_guests}).
        {:else}
          Select who you'd like to bring below (max {max_additional_guests}), or

          <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
          <label
            for="new-additional-guests-page"
            class="text-emerald-600 underline hover:no-underline cursor-pointer"
            on:keydown
          >
            add their details manually</label>.
        {/if}
      </div>

      <ContactList
        {sections}
        bind:selected_item_ids={selected_contact_ids}
        empty_text="We couldn't find anyone with those details."
      />
    {/if}

    <label
      for={$initial_page}
      class="w-full btn btn-primary mt-6"
    >{button_label(selected_contact_ids)}</label>
  </Loadable>
</Pages.Page>

<Pages.Page
  id="new-additional-guests-page"
  backPage={ contacts.length ? 'attendance-additional-guests' : $initial_page }
>
  <h3 class="text-2xl font-medium mt-3">Bring someone new</h3>

  <NewContactForm
    request_options={{ params: { event_id: event.id } }}
    on:success={(evt) => {
      const contact = evt.detail

      contacts = [...contacts, contact]
      selected_contact_ids = [...selected_contact_ids, contact.id]
      $active_page = 'attendance-additional-guests'
    }}
  />
</Pages.Page>
