<script>
  import { SlideTransition } from '$lib/components'

   /** @type {{ event: import('$types').Event, current_attendance: import('$types').Attendance }} */
  let { event, current_attendance } = $props()

  /** Hosts that are not the current attendee */
  let hosts = $derived(event.hosts
    .filter(({ id }) => id !== current_attendance?.user_id)
    .map(({ name }) => name || ''))
</script>

<div>
  <div
    class="{event.photo_url ? 'aspect-[16/9]' : 'aspect-[16/5.5]' } relative w-full bg-gradient-to-br from-slate-300 via-stone-300 to-slate-500 bg-cover bg-center rounded-box"
  >
    {#if event.photo_url}
      <img
        class="h-full w-full object-cover rounded-box shadow-xl hover:shadow-2xl transition-shadow"
        src="{ event.photo_url }&width=800"
        alt="{ event.title }"
      />
    {/if}

    {#if event.visibility === 'private'}
      <div class="absolute top-0 z-[1] p-3">
        <p
          class="rounded-btn bg-white/75 px-1.5 py-1 text-xs uppercase leading-none shadow backdrop-blur"
        >
          Private
        </p>
      </div>
    {/if}

    <div
      class="md:hidden backdrop-mask absolute bottom-0 flex max-h-full w-full items-end bg-gradient-to-b from-transparent via-black/60 to-black/90 backdrop-blur-[1px] rounded-box"
    >
      <div class="flex w-full justify-between items-end p-3 text-white space-x-2">
        <div class="flex h-full flex-col justify-end">
          <p class="font-light">
            {#if current_attendance?.is_host}
              {#if hosts.length}
               { new Intl.ListFormat('en').format(['You', ...hosts]) } are hosting
              {:else}
                You're hosting
              {/if}
            {:else}
              { new Intl.ListFormat('en').format(hosts) } { hosts.length === 1 ? 'has' : 'have' } invited you to
            {/if}
          </p>

          <SlideTransition key={event.title}>
            <h1 class="break-words text-2xl font-bold md:text-3xl">{ event.title }</h1>
          </SlideTransition>
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  @supports (mask: linear-gradient(transparent, black)) {
    .backdrop-mask {
      mask: linear-gradient(transparent, black 25px);
      @apply pt-10;
    }
  }
</style>
