<script>
  import { inertia } from '@inertiajs/svelte'
  import { Transition, Modal, InvitationPreview, Avatar, Form, WhatsAppMarkdown, UserList } from '$lib/components'

  import { arrayToSentence } from '$lib/utilities'

  /** @type {{ attendances: import('$types').Attendance[], events: import('$types').Event[], current_user: import('$types').User }} */
  let { attendances, events } = $props()

  /** @type {import('$types').Event} */
  let selected_event = $state()

  let form = {}

  let query = $state('')

  let filtered_events = $derived(events.filter((event) =>
    (event.title || '').toLowerCase().includes(query) ||
    (event.description || '').toLowerCase().includes(query)
  ))
</script>

<svelte:head>
  <title>Event invites • Occasionly</title>
</svelte:head>

<Transition
  key={ form?.attendances?.length || 0 }
  from={{ x: 10, duration: 200, delay: 150 }}
  to={{ x: -10, duration: 200 }}
>
  {#if form?.attendances?.length}
    {@const event = events.find(({ id }) => id === form.event_id )}

    {#if form.attendances.length > 2}
      <h1 class="text-3xl mt-4">
        We've invited the following people to <strong>{ event.title }</strong>:
      </h1>

      <div class="max-h-48 overflow-auto">
        <UserList color="emerald" users={ form.attendances.map(({ user }) => user) } class="mt-4" />
      </div>
    {:else}
      <h1 class="text-3xl mt-4">
        We've invited {@html form.attendances.map(({ user }) => `<strong>${ user.name }</strong>`).join(' and ')} to <em>{ event.title }</em>.
      </h1>
    {/if}


    <h2 class="text-xl mt-4">
      {#if attendances.length > 2}
        You've already invited
        <label for="contact-invite-modal" class="text-emerald-600 border-b-[1.5px] transition-colors border-current hover:border-transparent cursor-pointer">{attendances.length} other{ attendances.length === 1 ? '' : 's'}</label>.
      {:else}
        {@html attendances.map(({ user }) => `<strong>${ user.name }</strong>`).join(' and ')} { attendances.length === 1 ? 'was' : 'were'} already invited.
      {/if}
    </h2>
  {:else}
    <h1 class="text-3xl mt-4">
      {#if attendances.length > 2}
        You have
        <label for="contact-invite-modal" class="text-emerald-600 border-b-[1.5px] transition-colors border-current hover:border-transparent cursor-pointer">{attendances.length} contact{ attendances.length === 1 ? '' : 's'}</label>

        to invite.
      {:else}
        Invite {@html attendances.map(({ user }) => `<strong>${ user.name }</strong>`).join(' and ')} to an event.
      {/if}
    </h1>
  {/if}


<p class="mt-2">
  Select which event you want to invite them to below, <a use:inertia href="/events/new" class="text-emerald-600 border-b-[1.5px] transition-colors border-current hover:border-transparent">create a new one</a>, or <label class="cursor-pointer border-b-[1.5px] transition-colors border-current hover:border-transparent" for="cancel-modal">cancel</label>.
</p>

<div>
  <input
    class="w-full input input-bordered input-sm mt-2"
    placeholder="Search..."
    autocomplete="off"
    oninput={ ({ target }) => {
      query = target.value.toLowerCase()
    }}
  />
</div>

<div class="table w-full border-seperate overflow-hidden backdrop-blur-none divide-y shadow ring-1 ring-base-content/5 dark:ring-base-content/20 mt-2 md:rounded-md">
  <div class="max-h-72 w-full overflow-auto">

    <div class="table-row-group group w-full">
      {#each filtered_events as event (event.id)}

        <label for={ event.id } class="table-row transition-colors hover:bg-base-200">
          <div class="table-cell align-middle border-b border-boundary px-2 py-3 pl-4 sm:pl-6">
            <input
              type="radio"
              name="event_id"
              value={event}
              id={event.id}
              class="radio checked:radio-secondary radio-sm"
              bind:group={selected_event}
            />
          </div>

          <div class="table-cell align-middle border-b border-boundary px-2 py-3 w-full">
            <div class="">
              <h4 class="font-medium">
                { event.title }
              </h4>

              <WhatsAppMarkdown text={(event.description || '').slice(0, 250)} class="random text-sm text-gray-500 line-clamp-1" renderLinks={ false } />
            </div>
          </div>
        </label>
      {/each}

    </div>
  </div>

  {#if !filtered_events.length}
    <div class="table-row w-full">
      <div class="table-cell px-2 pt-3 pb-5 text-center space-y-3">
        <p class="text-gray-500">We couldn't find an event with those details.</p>

        <a use:inertia href="/events/new" class="btn btn-outline">Create an event</a>
      </div>
    </div>
  {/if}
</div>

<div class="flex justify-end items-center space-x-2 mt-3">
  <div>
    <p class="text-sm text-gray-500 text-right">
      <em>
        { arrayToSentence(attendances, { mapFn: ({ user }) => user.name }) }.
      </em>
    </p>
  </div>

  <label for="invite-confirmation-modal" class="btn btn-primary {!selected_event && 'btn-disabled'}">
    Invite
  </label>

  <label for="cancel-modal" class="btn">Cancel</label>
</div>
</Transition>

{#if selected_event}
  {@const invitees = attendances.map(({ user }) => user)}

  <Modal class="text-left" id="invite-confirmation-modal">
    <h3 class="text-2xl font-medium">Invite <strong>{ invitees.length } { invitees.length === 1 ? 'person' : 'people' }</strong> to { selected_event.title }</h3>

    <p class="mt-4 font-normal">
      We'll send { invitees.length === 1 ? 'an invite' : 'invites' } to { arrayToSentence(invitees, { mapFn: ({ name }) => name }) }.
    </p>

    <Form action="/unassigned_attendances" method="patch">
      <input type="hidden" name="event_id" value={ selected_event.id }>

      {#each invitees as invitee}
        <input type="hidden" name="user_id[]" value={invitee.id}>
      {/each}

      <InvitationPreview event={selected_event} class="mt-3" invite_count={invitees.length} />

      <button type="submit" class="w-full btn btn-primary mt-6">
        Send invite{ invitees.length > 1 ? 's' : ''}
      </button>
    </Form>
  </Modal>

{/if}

<Modal id="cancel-modal">
  <h3 class="text-2xl font-medium">Are you sure?</h3>

  <p class="mt-2">To invite {attendances.length === 1 ? 'this contact' : 'these contacts'} again, you'll need to re-upload them through WhatsApp.</p>

  <Form action="/unassigned_attendances" method="delete">
    <button type="submit" class="w-full mt-6 btn btn-error">
      Yes, cancel the invitation
    </button>
  </Form>
</Modal>

{#if attendances.length > 2}
  <Modal id="contact-invite-modal">
    <h3 class="text-2xl font-medium">Invite {attendances.length} {attendances.length === 1 ? 'person' : 'people'}</h3>

    <ul class="space-y-2 mt-2">
      {#each attendances as attendance}
        <li class="flex items-center justify-between">
          <div class="flex whitespace-nowrap flex-1">
            <Avatar user={ attendance.user } class="w-6 h-6" />

            <span class="ml-1.5">{ attendance.user.name }</span>
          </div>
        </li>
      {/each}
    </ul>
  </Modal>
{/if}
