<script>
  import { onMount } from 'svelte'
  import { page, inertia } from '@inertiajs/svelte'

  import { Modal, Form, TimeZonePicker } from '$lib/components'
  import { notification_channels } from '$lib/config'
  import { time_zone } from '$lib/stores'

  /** @type {import('$types').PageProps<'current_user'>} */
  let { current_user } = $derived($page.props)

  /** @type {typeof Modal} */
  let modal

  onMount(() => modal.show())

  let channel = $derived(notification_channels[current_user.notification_channels[0].name])
</script>

<Modal bind:this={modal} class="overflow-visible">
  <h3 class="text-2xl font-semibold">Set your name</h3>

  {#if !current_user.name}
    <p class="mt-3">Save your full name to create events and for others to invite you.</p>
  {:else}
    <p class="mt-3">We've updated your name from your {channel.name}, but we recommend setting your full name manually so others can easily invite you.</p>
  {/if}

  <Form
    action="/users/{current_user.id}"
    method="patch"
    class="mt-3"
    onsuccess={() => modal.hide()}
  >
    <input
      type="text"
      id="name-prompt"
      required
      name="name"
      class=" w-full font-medium input input-bordered"
      placeholder={current_user.name || "Gaius Pompeius Trimalchio"}
    />

    <h3 class="font-medium mt-3 mb-1">Time zone for events and notifications</h3>

    <TimeZonePicker value={$time_zone} />

    <p class="mt-3">To change where you receive notifications, or to add your social handles so other people can easily find you, head over to <a use:inertia href="/profile" class="underline hover:no-underline font-medium" onclick={() => modal.hide()}>your profile</a>.</p>

    <button type="submit" class="mt-6 w-full btn btn-primary">
      Save
    </button>
  </Form>
</Modal>
