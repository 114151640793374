<script>
  import { page } from '@inertiajs/svelte'

  import Errors from './Errors.svelte'
</script>

<label class="form-control w-full">
  <div class="label pb-0.5">
    <span class="label-text text-muted">Name</span>
  </div>

  <input
    class="input input-bordered w-full"
    placeholder="Gaius Pompeius Trimalchio"
    name="name"
    required
    autocomplete="name"
  />

  <Errors errors={$page.props.errors?.name} />
</label>

<label class="form-control w-full">
  <div class="label pb-0.5">
    <span class="label-text text-muted">Email or phone number</span>
  </div>

  <input
    class="input input-bordered w-full"
    placeholder="you@example.com"
    name="identifier"
    required
    autocomplete="email"
  />

  <Errors errors={$page.props.errors?.['notification_channels.identifier']} />
</label>
