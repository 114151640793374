<script>
  import { arrayPartition } from '$lib/utilities'

  import Avatar from './Avatar.svelte'

  /**
   * @type {import('$lib/types').User[]}
   */
  export let users = []

  /** @type {?string} */
  export let color = null

  let className = ''
  export { className as class }

  $: [named, nameless] = arrayPartition(users, ({ name }) => !!name)
</script>

<ul class="space-y-2 { className }">
  {#each named as user}
    <li class="flex items-center justify-between">
      <div class="flex items-center whitespace-nowrap flex-1">
        <Avatar {user} {color} class="w-7 h-7" />

        <span class="ml-1.5">{ user.name }</span>
      </div>

      <slot {user} />
    </li>
  {/each}

  {#if nameless.length}
    <li class="flex items-center">
      <div class="flex -space-x-3">
        {#each nameless.slice(0, 3) as _ }
          <Avatar color="gray" class="w-7 h-7 ring-2 relative text-gray-500 ring-white" />
        {/each}
      </div>

      <span class="ml-1.5">
        {#if named.length}
          &hellip;and { nameless.length } other{ nameless.length === 1 ? '' : 's' }
        {:else}
          { nameless.length } { nameless.length === 1 ? 'person' : 'people' }
        {/if}
      </span>
    </li>
  {/if}
</ul>
