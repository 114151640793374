<script>
  import { formatDistanceToNowStrict, parseJSON } from 'date-fns'
  import { slide } from 'svelte/transition'
  import { onDestroy } from 'svelte'

  import PostEntry from './PostEntry.svelte'
  import PollEntry from './PollEntry.svelte'
  import GroupChatEntry from './GroupChatEntry.svelte'

  import BroadcastEntryModal from './BroadcastEntryModal.svelte'
  import EntryReactions from './EntryReactions.svelte'
  import EditGroupChatModal from './EditGroupChatModal.svelte'

  import { Form, Modal } from '$lib/components'
  import { page } from '@inertiajs/svelte'

  let { current_user, current_attendance } = $derived($page.props)

  const entry_config = {
    GroupChat: {
      title: 'Group chat',
      icon: 'ionicons.chatbubbles-outline',
      textKey: 'title'
    },
    Post: {
      title: 'Post',
      icon: 'feather.edit',
      textKey: 'text'
    },
    Poll: {
      title: 'Poll',
      icon: 'feather.bar-chart-2',
      textKey: 'title'
    }
  }

  /** @type {{ entry: import('$lib/types').Entry }} */
  let { entry } = $props()

  /** @type {string} */
  let time = $state()

  /** @type {NodeJS.Timeout} */
  let timer = $state()

  const update_time = (timeout = 2) => {
    time = formatDistanceToNowStrict(parseJSON(entry.created_at), { addSuffix: true })

    timer = setTimeout(update_time.bind(null, timeout**1.25), timeout * 1000)
  }

  update_time()

  onDestroy(() => clearTimeout(timer))

  let user_can_destroy = $derived(current_user?.id === entry.creator.id || current_attendance?.is_host)
  let is_editable = $derived(entry.entryable_type === 'GroupChat' && current_user?.id === entry.creator.id)
</script>

<li transition:slide={{ duration: 300 }} class="py-3">
  <div>
    <div class="relative dropdown dropdown-end w-full">
      <div
        tabindex="0"
        role="button"
        class="w-full flex justify-between items-center"
        class:cursor-default={!user_can_destroy}
      >
        <p class="text-sm text-muted">
          <em>
            {time} by {entry.creator.name}
          </em>
        </p>

        {#if user_can_destroy}
          <span class="i-feather-more-vertical text-muted -mr-[6px]"></span>
        {/if}
      </div>

      {#if user_can_destroy}
        <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
        <ul tabindex="0" class="mt-1 p-2 shadow menu menu-sm dropdown-content z-[1] bg-base-100 rounded-box">
          {#if current_user?.id === entry.creator.id && current_attendance?.is_host}
            <li>
              <label for="broadcast-entry-modal-{entry.id}">
                <span class="i-ion-megaphone-outline"></span>
                Broadcast
              </label>
            </li>
          {/if}

          {#if is_editable}
            <li>
              <label for="edit-entry-modal-{entry.id}">
                <span class="i-feather-edit-3"></span>
                Edit
              </label>
            </li>
          {/if}

          <li>
            <label for="delete-entry-modal-{entry.id}" class="text-rose-500">
              <span class="i-feather-trash"></span>
              Delete
            </label>
          </li>
        </ul>
      {/if}
    </div>

    {#if entry.entryable_type === 'Post'}
      <PostEntry {entry} />
    {:else if entry.entryable_type === 'Poll'}
      <PollEntry {entry} />
    {:else if entry.entryable_type === 'GroupChat'}
      <GroupChatEntry {entry} />
    {/if}
  </div>

  <EntryReactions {entry} class="mt-1" />

  <!-- Broadcast modal -->
  {#if current_attendance?.is_host}
    <BroadcastEntryModal {entry} id="broadcast-entry-modal-{entry.id}" />
  {/if}

  <!-- Edit modal -->
  {#if is_editable}
    <EditGroupChatModal {entry} />
  {/if}

  <!-- Delete modal -->
  {#if user_can_destroy}
    <Modal id="delete-entry-modal-{entry.id}">
      <h3 class="text-xl font-semibold">Are you sure?</h3>

      <p class="mt-2">This will delete { current_user?.id === entry.creator.id ? 'the' : `${ entry.creator.name.split(' ')[0] }'s` } {entry_config[entry.entryable_type].title.toLowerCase()} for everyone.</p>

      <Form
        action="/entries/{entry.id}"
        method="delete"
       >
        <input type="hidden" name="entryable_type" value={ entry.entryable_type } />

        <button type="submit" class="mt-4 w-full btn btn-error">
          Yes, delete
        </button>
      </Form>
    </Modal>
  {/if}
</li>
