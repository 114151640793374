<script>
  import { flip } from 'svelte/animate'
  import { scale } from 'svelte/transition'

  import { Avatar } from '$lib/components'

  /** @type {string[]} */
  export let selected_item_ids

  /** @type {import('$lib/types').ContactListItem[]} */
  export let items

  $: selected_items = selected_item_ids.reduce(
    /**
     * @param {import('$lib/types').ContactListItem[]} selected_items
     * @param {string} id
     */
    (selected_items, id) => {
      let item = items.find(item => item.id === id)

      if (item) return [...selected_items, item]

      return selected_items
    }, [])
</script>

<div class="overflow-x-auto sticky top-0 {selected_item_ids.length ? 'h-[62px]' : 'h-0'} transition-[height] duration-300 bg-base-100 z-10">

  <div class="flex pb-1">
    {#each selected_items as item (item.id) }
      <div class="px-1.5 flex flex-col items-center" in:scale={{ delay: 100 }} out:scale animate:flip={{ duration: 250 }}>
        <div class="relative">
          <Avatar user={item} class="h-10 w-10 !text-sm" />

          <div class="absolute flex items-center justify-center top-[-3px] right-[-3px] rounded-full bg-base-200 shadow">
            <input
              type="checkbox"
              value={item.id}
              class="peer appearance-none block h-[1rem] w-[1rem] cursor-pointer"
              checked
              on:change={ ({ target }) => {
                if (target?.checked) {
                  selected_item_ids = [...selected_item_ids, item.id]
                } else {
                  selected_item_ids = selected_item_ids.filter(_id => _id !== item.id)
                }
              }}
            />

            <span class="i-feather-x h-3 w-3 absolute pointer-events-none text-base-content/70"></span>
          </div>
        </div>

        <p class="mt-0.5 text-[10px] text-base-content/70 truncate text-center max-w-full w-10">{ item.name?.split?.(' ')?.[0] }</p>
      </div>
    {/each}
  </div>
</div>
