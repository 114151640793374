<script>
  import { store } from '$lib/stores'
  import { page } from '@inertiajs/svelte'

  import { Modal, Form } from '$lib/components'

  import GroupChatForm from './GroupChatForm.svelte'

  /** @type {{ event: import('$types').Event }} */
  let { event } = $derived($page.props)

  let { onsuccess, ...restProps } = $props()

  let title = $state($store[`group_chat:${ event.id }`] || '')

  $effect(() => {
    $store[`group_chat:${ event.id }`] = title ? title : null
  })

  let modal = $state()
</script>

<!-- <CreateEntryModal
  on:open={ ({ detail: modalEl }) => {
    const text_area = modalEl.querySelector('input[name="entryable_attributes[title]"]')

    text_area.focus()
    text_area.setSelectionRange(text_area.value.length, text_area.value.length)
  }}
  on:success
  on:success={ () => $store[`group_chat:${event.id}`] = null }
  label="Create group chat"
  disabled={!title.length}
  {...restProps}
>
  <input type="hidden" name="entryable_type" value="GroupChat">

  <h3 class="text-2xl font-semibold">Create a group chat</h3>

  <GroupChatForm bind:title />
</CreateEntryModal>
 -->
<Modal
  bind:this={modal}
  onopen={() => {
    // const text_area = modal.querySelector('input[name="entryable_attributes[title]"]')

    // text_area.focus()
    // text_area.setSelectionRange(text_area.value.length, text_area.value.length)
  }}
  class="max-w-md"
  {...restProps}
>
  <Form
    action="/entries"
    onsuccess={ () => {
      modal.hide()
      $store[`group_chat:${event.id}`] = null
    }}
    bind_keyboard_submission
  >
    <input type="hidden" name="entryable_type" value="GroupChat">
    <input type="hidden" name="event_id" value={event.id}>

    <h3 class="text-2xl font-semibold">Create a group chat</h3>

    <GroupChatForm bind:title />

    <div class="relative mt-3">
      <button
        class="btn btn-primary w-full"
        type="submit"
        disabled={!title.length}
      >
        Create group chat
      </button>

      <div class="absolute flex items-center inset-y-0 right-3 gap-1">
        <kbd class="kbd kbd-sm kbd-primary">⌘</kbd>
        <kbd class="kbd kbd-sm kbd-primary">⏎</kbd>
      </div>
    </div>
  </Form>
</Modal>
