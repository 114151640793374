<script>
  import { page } from '@inertiajs/svelte'

  /** @type {{ ongenerate: function(string):void, class: string }} */
  let { ongenerate, class: class_name = '' } = $props()

  let loading = $state(false)

  let prompt = $state('')

  const generate_image = async () => {
    if (loading || !prompt) return

    loading = true

    try {
      const { url } = await fetch('/images/generate', {
        method: 'POST',
        body: JSON.stringify({ prompt }),
        headers: {
          'X-CSRF-Token': String($page.props.csrf_token),
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())

      ongenerate(url)
    } finally {
      loading = false
    }
  }
</script>

<div class="w-full join {class_name}">
  <div class="w-full relative">
    <input
      type="text"
      class="input input-bordered w-full join-item"
      placeholder="A futuristic oil painting of revellers travelling through time&hellip;"
      bind:value={prompt}
      onkeydowncapture={ (evt) => {
        if (evt.key === 'Enter') {
          evt.preventDefault()

          generate_image()
        }
      }}
    />
    <!-- <Errors errors={$page.form?.errors?.image_prompt} /> -->
  </div>

  <button type="submit" class="btn [&:not(:disabled)]:btn-outline z-[1] join-item {loading && 'is-loading'}" disabled={!prompt || loading} onclick={generate_image}>
    <span class="i-feather-image w-5 h-5"></span>

    <span class="max-sm:hidden">
      {#if loading}
        Generating&hellip;
      {:else}
        Generate
      {/if}
    </span>
  </button>
</div>