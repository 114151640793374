import RootLayout from '../../views/layouts/root.svelte'
import StaticLayout from '../../views/layouts/static.svelte'
import ProseLayout from '../../views/layouts/prose.svelte'
import AppLayout from '../../views/layouts/app.svelte'

const pages = import.meta.glob('../../views/**/*.(svelte|md)', { eager: true })

/** @param {string} name */
export const resolve = (name) => {
  const page = pages[`../../views/${name}.svelte`] || pages[`../../views/${name}.md`]

  let layout

  if (name.startsWith('static')) {
    layout = StaticLayout
  } else if (name.startsWith('prose')) {
    layout = ProseLayout
  } else {
    layout = AppLayout
  }

  return { default: page.default, layout: [RootLayout, page.layout || layout] }
}
