<script>
  import { page } from '@inertiajs/svelte'

  import { Transition, WhatsAppMarkdown, Form } from '$lib/components'

  let { event } = $derived($page.props)

  /** @type {{ entry: import('$lib/types').Entry<'Poll'> }} */
  let { entry } = $props()

  let poll = $derived(entry.entryable)
  let user_has_voted = $derived(poll.options.some(({ user_votes_count }) => user_votes_count > 0))
  let total_votes = $derived(poll.options.reduce((total, option) => total + option.votes_count, 0))
  let max_vote = $derived(Math.max(...poll.options.map(({ votes_count }) => votes_count)))

  let selected_option = ''
</script>

<WhatsAppMarkdown text={ poll.title } class="mt-1 whitespace-pre-line" />

<Transition
  class="w-full"
  key={ user_has_voted }
  from={{ x: 10, duration: 250, delay: 200 }}
  to={{ x: -10, duration: 250 }}
>
  {#if user_has_voted}
    <ul class="mt-3 space-y-1 w-full">
      {#each poll.options as option }
        {@const vote_percentage = 100 * option.votes_count / total_votes }

        <li class="relative">
          <div class="absolute left-0 top-0 h-full rounded-btn {option.votes_count >= max_vote ? 'bg-primary' : 'bg-base-300 dark:bg-base-100'}" style="width: { vote_percentage }%"></div>

          <div class="relative flex justify-between items-center w-full space-x-1" class:font-bold={option.votes_count >= max_vote}>
            <div
              class="flex items-center relative py-1 px-2 break-all"
              class:text-emerald-900={option.votes_count >= max_vote}
            >
              <span class="w-6 flex-shrink-0">
                {#if option.user_votes_count > 0 }
                  <span class="i-feather-check-circle h-3.5 w-3.5"></span>
                {/if}
              </span>

              <WhatsAppMarkdown text={option.text} class={ option.votes_count >= max_vote ? '[&>a]:text-primary-content' : '[&>a]:text-base-content' } />
            </div>

            <span class="relative">{vote_percentage.toFixed(1)}%</span>
          </div>
        </li>
      {/each}
    </ul>

    <div class="h-10 mt-1.5 flex items-center">
      <p class="text-base text-zinc-500">{total_votes} vote{total_votes === 1 ? '' : 's'}</p>
    </div>
  {:else}
    <Form id={poll.id} action="/polls/{ poll.id }/votes">
      <ul class="mt-1.5 space-y-1 w-full">
        {#each poll.options as option }

          <li class="relative py-1">
            <div class="flex space-x-2">
              <input
                type="radio"
                id="option_{ option.id }"
                name="poll_option_id"
                form={poll.id}
                value={option.id}
                bind:group={selected_option}
                class="radio checked:radio-secondary"
              />
                <!-- color={selected_option === option.id ? 'primary' : 'neutral'} -->

              <label for="option_{option.id}" >
                <WhatsAppMarkdown text={option.text} />
              </label>
            </div>
          </li>
        {/each}
      </ul>

      <div class="h-10 mt-3 flex">
        <button type="submit" class="btn btn-primary h-10 min-h-10">Vote</button>
      </div>
    </Form>
  {/if}
</Transition>
