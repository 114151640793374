<script>
  import { fly } from 'svelte/transition'

  /**
   * Use this to trigger an outro/intro animation, useful for e.g. page transitions.
   *
   * @type {any}
   * @optional
   */
  export let key = ''

  /**
   * Use this to control the intro/outro animations.
   *
   * @type {boolean}
   * @optional
   */
  export let visible = true

  /**
   * An object of properties to transition from.
   *
   * @default { delay: 0, duration: 300, x: 0, y: 0, opacity: 0 }
   * @type {Object}
   * @property {number} delay
   * @property {number} duration
   * @property {function} easing
   * @property {number} x
   * @property {number} y
   * @property {number} opacity
   */
  export let from = {}

  /**
   * An object of properties to transition to.
   *
   * @default { delay: 0, duration: 300, x: 0, y: 0, opacity: 0 }
   * @type {Object}
   * @property {number} delay
   * @property {number} duration
   * @property {function} easing
   * @property {number} x
   * @property {number} y
   * @property {number} opacity
   */
  export let to = {}

  /**
   * @type {string}
   * @alias class
   */
  let class_name = ''
  export { class_name as class }

  /**
   * Fires when the intro animation starts.
   *
   * @type {function}
   * @param {CustomEvent} event
   * @event introstart
   */

  /**
   * Fires when the intro animation finishes.
   *
   * @type {function}
   * @param {CustomEvent} event
   * @event introend
   */

  /**
   * Fires when the outro animation starts.
   *
   * @type {function}
   * @param {CustomEvent} event
   * @event outrostart
   */

  /**
   * Fires when the outro animation finishes.
   *
   * @type {function}
   * @param {CustomEvent} event
   * @event outroend
   */
</script>

{#if visible}
  <div class="transition {class_name}">
    {#key key}
      <div
        on:introstart
        on:introend
        on:outrostart
        on:outroend
        in:fly|local={from}
        out:fly|local={to}
      >
        <slot />
      </div>
    {/key}
  </div>
{/if}

<style lang="postcss" global>
  .transition {
    display: grid;
    align-items: start;
  }

  .transition > div {
    grid-area: 1 / 1 / 1 / 1;
    min-width: 0px;
  }
</style>
