<script>
  /** @type {{ children: import('svelte').Snippet, loading: boolean, class?: string }} */
  let { children, loading = false, class: class_name = '' } = $props()
</script>

{#if loading}
  <div class="flex items-center justify-center h-[72px] {class_name}">
    <span class="loading w-5 h-5"></span>
  </div>
{:else}
  {@render children()}
{/if}
