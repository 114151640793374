<script>
  /*import { page } from '@inertiajs/svelte'*/

  import { Form, NotificationChannels, SocialChannels, TimeZonePicker, ProSubscriptionButton } from '$lib/components'

  /** @type {{ current_user: import('$types').User }} */
  let { current_user } = $props()

  let notification_channel_modals = $state()

  const pricing_text = 'Occasionly charges an 8% fee per ticket, with a 50p minimum. This includes all transaction costs.'

  let is_payment_button_loading = $state(false)
</script>

<svelte:head>
  <title>Profile • Occasionly</title>
</svelte:head>

<Form action="/users/{current_user.id}" method="patch">
  <div class="flex items-center justify-between w-full md:space-x-3">
    <div class="flex items-center space-x-3 w-full">
      <div class="avatar">
        <div class="w-20 rounded-full">
          {#if current_user.photo_url}
            <img class="w-full h-full" src={current_user.photo_url} alt={current_user.photo_url ? current_user.name : ''} />
          {:else}
            <div class="flex justify-center items-center h-full w-full bg-base-200">
              <span class="i-ion-person w-10 h-10 text-neutral/30"></span>
            </div>
          {/if}
        </div>
      </div>

      <input
        type="text"
        id="name"
        required
        name="name"
        value={current_user.name}
        class="font-semibold input input-bordered text-lg w-full"
        placeholder="Gaius Pompeius Trimalchio"
      />
    </div>

    <div
      class="max-sm:p-3 sm:pl-3 z-[2] flex justify-end max-sm:fixed bottom-0 inset-x-0 bg-base-100 sm:!shadow-none" style="box-shadow: 0 -4px 6px -1px rgb(0 0 0 / 0.1), 0 -2px 4px -2px rgb(0 0 0 / 0.1);"
    >

      <button
        type="submit"
        class="btn btn-primary whitespace-nowrap"
      >
        <span class="i-feather-save h-4 w-4"></span>
        Save
      </button>
    </div>
  </div>

  <NotificationChannels user={current_user} bind:modals={notification_channel_modals} />

  <div class="mt-8">
    <h3 class="text-2xl font-medium">Socials</h3>

    <p class="mt-1">
      Add your social media handles and we'll link to them on guest lists.
    </p>

    <SocialChannels user={current_user} class="space-y-3 mt-3" />
  </div>

  <div class="mt-8">
    <h3 class="text-2xl font-medium">Time zone</h3>

    <p class="my-1">We use your time zone setting for events and notifications.</p>

    <TimeZonePicker value={current_user.time_zone} />
  </div>
</Form>

<div class="mt-8 hidden">
  <div class="flex items-center space-x-2">
    <h3 class="text-2xl font-medium">Paid events</h3>

    {#if current_user.payments_enabled}
      <span class="i-feather-check-circle h-6 w-6 text-success"></span>
    {:else if current_user.stripe_account_id}
      <span class="i-feather-alert-triangle h-6 w-6 text-amber-400"></span>
    {/if}
  </div>

  {#if current_user.payments_enabled}
    <div class="mt-1 flex flex-col sm:flex-row items-start space-y-3 sm:space-y-0 sm:space-x-3">
      <p class="flex-1">You're setup and ready to create paid events! {pricing_text}</p>

      <Form action="/stripe/login_links">
        <button class="btn btn-primary text-base h-10 min-h-10">
          Stripe dashboard

          <span class="i-feather-external-link h-4 w-4"></span>
        </button>
      </Form>
    </div>
  {:else}
    <div class="mt-1 flex flex-col sm:flex-row items-start space-y-3 sm:space-y-0 sm:space-x-3">
      <div class="space-y-3 flex-1">
        <p>To create paid events you need to { current_user.stripe_account_id ? 'completete the setup' : 'setup an account' } with our payments provider, Stripe.</p>

        <p>{pricing_text}</p>
      </div>

      <Form action={current_user.stripe_account_id ? '/stripe/account_links' : '/stripe/accounts'}>
        <button
          class="btn h-10 min-h-10 sm:w-auto w-full { current_user.stripe_account_id ? 'btn-primary' : 'btn-ghost py-1.5 bg-[#635bff] hover:bg-[#635bff]/80 text-white' }"
        >
          {#if current_user.stripe_account_id}
            Complete setup
          {:else}
            <div class="flex items-center">
              Connect with

              <svg version="1.1" class="h-[1.75rem] -mb-px" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 468 222.5" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor" d="M414 113.4c0-25.6-12.4-45.8-36.1-45.8-23.8 0-38.2 20.2-38.2 45.6 0 30.1 17 45.3 41.4 45.3 11.9 0 20.9-2.7 27.7-6.5v-20c-6.8 3.4-14.6 5.5-24.5 5.5-9.7 0-18.3-3.4-19.4-15.2h48.9c0-1.3.2-6.5.2-8.9zm-49.4-9.5c0-11.3 6.9-16 13.2-16 6.1 0 12.6 4.7 12.6 16h-25.8zM301.1 67.6c-9.8 0-16.1 4.6-19.6 7.8l-1.3-6.2h-22v116.6l25-5.3.1-28.3c3.6 2.6 8.9 6.3 17.7 6.3 17.9 0 34.2-14.4 34.2-46.1-.1-29-16.6-44.8-34.1-44.8zm-6 68.9c-5.9 0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9 6.2-4.9 11.9-4.9 9.1 0 15.4 10.2 15.4 23.3 0 13.4-6.2 23.4-15.4 23.4zM223.8 61.7l25.1-5.4V36l-25.1 5.3zM223.8 69.3h25.1v87.5h-25.1zM196.9 76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7 15.9-6.3 19-5.2v-23c-3.2-1.2-14.9-3.4-20.8 7.4zM146.9 47.6l-24.4 5.2-.1 80.1c0 14.8 11.1 25.7 25.9 25.7 8.2 0 14.2-1.5 17.5-3.3V135c-3.2 1.3-19 5.9-19-8.9V90.6h19V69.3h-19l.1-21.7zM79.3 94.7c0-3.9 3.2-5.4 8.5-5.4 7.6 0 17.2 2.3 24.8 6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6C67.5 67.6 54 78.2 54 95.9c0 27.6 38 23.2 38 35.1 0 4.6-4 6.1-9.6 6.1-8.3 0-18.9-3.4-27.3-8v23.8c9.3 4 18.7 5.7 27.3 5.7 20.8 0 35.1-10.3 35.1-28.2-.1-29.8-38.2-24.5-38.2-35.7z"/></svg>
            </div>
          {/if}
        </button>
      </Form>
    </div>
  {/if}
</div>

{@render notification_channel_modals()}

<div class="mt-8">
  <div class="flex items-center space-x-2">
    <h3 class="text-2xl font-medium">Occasionly Pro</h3>

    {#if current_user.subscription}
      <span class="i-feather-check-circle h-6 w-6 text-success"></span>
    {/if}
  </div>

  <div class="mt-1 flex flex-col sm:flex-row items-start space-y-3 sm:space-y-0 sm:space-x-3">
    {#if current_user.subscription}
      <p class="flex-1">You're subscribed to our pro plan and can export your events as spreadsheets, generate event images, and more.</p>

      <Form action="/stripe/billing_portal_sessions">
        <button class="btn btn-primary text-base h-10 min-h-10">Manage your plan</button>
      </Form>
    {:else}
      <p class="flex-1">Subscribe to our pro plan to export your events as spreadsheets, generate event images, and more.</p>

      <ProSubscriptionButton class="h-10 min-h-10" />
    {/if}
  </div>
</div>