import { notifications } from '$lib/config'
import { random_id } from '$lib/utilities'

/**
 * @param {keyof typeof notifications} key
 * @param {Record<string, any>} params
 * @returns {{ type: string; title: string; body?: string; id: string }}
 */
export const create_notification = (key, params = {}) => ({
  ...notifications[key](params),
  id: random_id()
})
