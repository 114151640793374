<script>
  import { page } from '@inertiajs/svelte'
  import { isPast } from 'date-fns'
  import { untrack, tick } from 'svelte'
  import { arrayPartition } from '$lib/utilities'
  import { attendance_states } from '$lib/config'
  import { misc } from '$lib/stores'
  import { Modal, Avatar, Form, Pages, Loadable, MaybeForm, SlideTransition, Tooltip } from '$lib/components'

  import AttendeeDetails from './AttendeeDetails.svelte'
  import AttendanceItem from './AttendanceItem.svelte'

  /** @type {import('$types').PageProps<'event' | 'current_attendance'>} */
  let { event, current_attendance } = $derived($page.props)

  let { going, invited, maybe } = $derived(event.attendee_stats)

  let user_has_responded = $derived(current_attendance && (current_attendance.is_host || !['payment_required', 'invited'].includes(current_attendance.status)))

  let _attendanceStates = $derived(attendance_states(event))

  /** @type {import('$lib/types').Attendance[]} */
  let attendances = $state([])

  /** @type {boolean} */
  let is_loading = $state(true)

  let filtered_attendances = $derived(
    attendances.filter(({ user, status, is_host }) =>
      (user?.name || '').toLowerCase().includes(query.toLowerCase()) ||
      status.includes(query.toLowerCase()) ||
      (is_host && 'hosts'.includes(query.toLowerCase())) ||
      (_attendanceStates[status]?.title || '').toLowerCase().includes(query.toLowerCase())
    )
  )

  let active_states = $derived(
    Object.values(_attendanceStates).filter(state => filtered_attendances.some(({ status }) => status === state.key))
  )

  $effect(() => {
    if (modal_open) {
      untrack(async () => {
        await fetch_attendances()
        search_input_el?.focus()
      })
    }
  })

  const fetch_attendances = async () => {
    if (!attendances.length) is_loading = true

    attendances = await fetch(
      `/attendances?event_id=${event.id}&expand[]=attendance.user&expand[]=attendance.notification`
    ).then(res => res.json())

    is_loading = false
  }

  const sort_by_probability = (a, b) => b.probability - a.probability

  /** @type {number} */
  let active_attendance_index = $state(-1)
  let active_attendance = $derived(attendances[active_attendance_index])

  /** @type {string} */
  let query = $state('')

  /** @type {boolean} */
  let modal_open = $state(false)

  let search_input_el = $state()
  let is_search_input_focused = $state(false)

  let guest_list_modal_label = $state()
  let invitation_modal_button = $state()

  /** @type {Modal} */
  let host_modal = $state()

  /** @type {number} */
  let guest_list_height = $state()

  /** @type {Pages.Body} */
  let pages = $state()
</script>

<svelte:window
  onkeydown={(evt) => {
    if (!user_has_responded) return

    const is_input = ['INPUT', 'TEXTAREA'].includes(evt.target?.tagName) && !['checkbox', 'radio'].includes(evt.target?.type)

    if (!is_input && !(evt.ctrlKey || evt.metaKey)) {
      const key = evt.key.toLowerCase()

      switch (key) {
      case '/':
        if (modal_open) {
          evt.preventDefault()
          search_input_el.focus()
        }

        break;
      case 'g':
        guest_list_modal_label.click()
        break;
      case 'i':
        if (modal_open) {
          evt.preventDefault()
          evt.stopPropagation()
          invitation_modal_button.click()
        }

        break;
      }
    }
  }}
/>

{#if event.is_guest_list_visible || current_attendance?.is_host}
  <div class="flex justify-between items-start space-x-3">
    <label bind:this={guest_list_modal_label} class="tooltip-hover relative group block w-full {user_has_responded && 'cursor-pointer' }" for={ user_has_responded ? 'guest-list' : '' }>
      <SlideTransition key={ current_attendance?.status }>
        <div class="flex justify-between w-full">
          <div>
            <div class="flex gap-1.5">
              <p class="font-semibold text-lg">Guest list</p>

              {#if event.attendance_limit }
                <span class="text-muted mt-px">(max {event.attendance_limit})</span>
              {/if}
            </div>

            <div class="flex items-end">
              <p class="transition-colors">
                { going } { isPast(new Date(event.end_time)) ? 'went' : 'going' } {
                  maybe && user_has_responded ? `• ${ maybe } maybe` : ''
                } {
                  invited && user_has_responded ? `• ${ invited } invited` : ''
                } {#if !user_has_responded}• <span class="text-muted">RSVP for the guest list</span>{/if}
              </p>
            </div>
          </div>

          {#if !event.is_guest_list_visible && current_attendance?.is_host }
            <div
              role="status"
              class="dropdown dropdown-top md:dropdown-center dropdown-end dropdown-hover"
            >
              <div class="indicator">
                <span class="indicator-item badge bg-white shadow shadow-amber-500/75 badge-sm border-transparent h-4 w-4">
                  <span class="text-amber-500">!</span>
                </span>

                <div class="btn btn-sm btn-outline group-hover:btn-active">
                  Guest list <span class="md:hidden">&rarr;</span>

                  <kbd class="kbd kbd-sm -mr-2">g</kbd>
                </div>
              </div>

              <div class="dropdown-content menu bg-base-100 rounded-btn z-[1] px-2 py-1 shadow-md border border-base-200 mb-1.5 w-60 text-center">
                <p>Only hosts can see the guest list.</p>
              </div>
            </div>
          {:else if user_has_responded}
            <div class="btn btn-sm btn-outline group-hover:btn-active">
              Guest list <span class="md:hidden">&rarr;</span>

              <kbd class="kbd kbd-sm -mr-2">g</kbd>
            </div>
          {/if}
        </div>
      </SlideTransition>
    </label>
  </div>
{/if}

{#snippet is_host_badge()}
  <div class="badge badge-primary gap-1 mr-5">
    <span class="i-mdi-crown-outline h-4 w-4 -ml-1"></span>

    Host
  </div>
{/snippet}

<Modal
  id="guest-list"
  bind:open={modal_open}
  class="sm:!max-w-md"
>
  <Pages.Body bind:this={pages}>
    <Pages.Page>
      <h3 class="text-2xl font-semibold">Guest list</h3>

      <Loadable loading={is_loading}>
        {#if attendances.length > 3}
          <label class="input input-bordered input-sm flex items-center gap-2 mt-2 pr-1">
            <input
              bind:this={search_input_el}
              onfocus={() => is_search_input_focused = true}
              onblur={() => is_search_input_focused = false}
              class="grow"
              placeholder="Search..."
              autocomplete="off"
              onkeydown={(evt) => {
                if (evt.key === 'Escape') search_input_el.blur()
               }}
              bind:value={query}
            />

            {#if !is_search_input_focused}
              <kbd class="kbd kbd-sm">/</kbd>
            {/if}
          </label>
        {/if}

        <div class="overflow-y-auto max-h-96" bind:clientHeight={guest_list_height} style="height: { guest_list_height ? guest_list_height + 'px' : 'auto' };">
          {#each active_states as state}
            {@const scopedAttendances = filtered_attendances.filter(({ status }) => status === state.key).sort(sort_by_probability) }
            {@const [named, nameless] = arrayPartition(scopedAttendances, ({ user }) => !!user?.name) }

            <div class="flex items-center mt-3">
              <div class="w-6 flex items-center justify-center">
                <span class="{state.icon} h-5 w-5"></span>
              </div>

              <h3 class="text-lg font-semibold ml-1.5">{ state.title } ({ scopedAttendances.length })</h3>
            </div>

            <ul class="space-y-2 mt-2">
              {#each named as attendance}
                <AttendanceItem
                  {attendance}
                  {query}
                  color={state.color}
                  select_attendance={
                    (attendance) => active_attendance_index = attendances.findIndex(({ id }) => id === attendance.id)
                  }
                />
              {/each}

              {#if nameless.length}
                <li class="flex items-center">
                  <div class="flex -space-x-3">
                    {#each nameless.slice(0, 3) as _ }
                      <Avatar color="gray" class="w-6 h-6 ring-2 relative text-muted ring-base-100" />
                    {/each}
                  </div>

                  <span class="ml-1.5">
                    {#if named.length}
                      and { nameless.length } other{ nameless.length === 1 ? '' : 's' }
                    {:else}
                      { nameless.length } { nameless.length === 1 ? 'person' : 'people' }
                    {/if}
                  </span>
                </li>
              {/if}
            </ul>
          {/each}

          {#if !filtered_attendances.length}
            <div class="px-2 py-3 flex items-center justify-center">
              <p class="text-center text-muted">We couldn't find anyone with those details.</p>
            </div>
          {/if}
        </div>
      </Loadable>

      {#if current_attendance?.is_host || event.guests_can_invite}
        <div class="relative mt-3">
          <button
            onclick={ () => {
              modal_open = false

              $misc.invite_modal?.show()
            }}
            bind:this={invitation_modal_button}
            class="w-full btn btn-primary"
          >
            Invite people
          </button>

          <div class="absolute flex items-center inset-y-0 right-3">
            <kbd class="kbd kbd-sm kbd-primary">i</kbd>
          </div>
        </div>
      {/if}
    </Pages.Page>

    {#if active_attendance}
      <Pages.Page id="attendee-page" corner={active_attendance.is_host && is_host_badge}>
        <AttendeeDetails
          attendance={active_attendance}
          onupdate={() => {
            pages.reset()
            fetch_attendances()
          }}
          ondestroy={() => {
            pages.reset()
            fetch_attendances()
            active_attendance_index = -1
          }}
        />
      </Pages.Page>

      <Pages.Page id="attendee-maybe-page">
        <MaybeForm
          attendance={active_attendance}
          action="/attendances/{active_attendance.id}"
          method="patch"
          onsuccess={() => {
            pages.reset()
            fetch_attendances()
          }}
        />
      </Pages.Page>

      {#if current_attendance?.is_host}
        <Pages.Page id="attendee-host-page">
          <h3 class="text-2xl">Make <span class="font-semibold">{active_attendance.user?.name}</span> an event host</h3>

          <p class="mt-3 text-rose-500">Hosts can invite people, invite others to host, send event updates and change event details. They can also see the names for any contacts you've uploaded.</p>

          <p class="mt-3">We'll send them an invite to host, which they first need to accept.</p>

          <Form
            action="/attendances/{active_attendance.id}"
            method="patch"
            onsuccess={ () => {
              pages.pop()
              fetch_attendances()
            }}
          >
            <button
              name="can_host"
              value="true"
              type="submit"
              class="mt-6 w-full btn btn-primary"
            >Invite to host</button>
          </Form>
        </Pages.Page>
      {/if}
    {/if}
  </Pages.Body>
</Modal>
