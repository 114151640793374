import { on } from 'svelte/events'

/** @type {[string, function][]} */
let event_listeners = $state([])

/**
 * @param {string} key
 * @param {function} fn
 */
export const add_escape_listener = (key, fn) => event_listeners.push([key, fn])

/** @param {string} key */
export const remove_escape_listener = (key) => {
  const index = event_listeners.findIndex(([k]) => k === key)

  event_listeners.splice(index, 1)
}

if (typeof window !== 'undefined') {
  on(window, 'keydown', (evt) => {
    const is_input =
      ['INPUT', 'TEXTAREA'].includes(evt.target?.tagName) &&
      !['checkbox', 'radio'].includes(evt.target?.type)

    if (!is_input && !(evt.ctrlKey || evt.metaKey) && evt.key === 'Escape') {
      if (event_listeners.length === 0) return

      // @ts-ignore
      const [_key, fn] = event_listeners.pop()

      fn.call(this, evt)
    }
  })
}
