<script>
  import { inertia } from '@inertiajs/svelte'
  const links = [
    {
      path: '/',
      title: 'Home'
    },
    {
      path: '/updates',
      title: 'Updates'
    },
    {
      path: '/terms',
      title: 'Terms'
    },
    {
      path: '/privacy',
      title: 'Privacy'
    }
  ]
</script>

<footer class="flex-1">
  <div class="mx-auto max-w-7xl overflow-hidden px-6 py-6 lg:px-8">
    <nav class="-mb-6 flex columns-2 justify-center space-x-6 sm:space-x-12" aria-label="Footer">
      {#each links as link}
        <div class="pb-6">
          <a use:inertia href="{link.path}" class="text-sm leading-6 text-base-content/70 hover:text-base-content"
            >{link.title}</a
          >
        </div>
      {/each}
    </nav>

    <p class="mt-3 text-center text-xs leading-5 text-base-content/70">
      &copy; { new Date().getFullYear() } Occasionly
    </p>
  </div>
</footer>
