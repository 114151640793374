<script>
  import { Modal, CalendarFeeds, SubscribeToGcal } from '$lib/components'

  let { current_user } = $props()
</script>

<svelte:head>
  <title>Calendars • Occasionly</title>
</svelte:head>

<h1 class="text-4xl font-semibold">Your calendar</h1>

<p class="mt-3 font-normal">
  Subscribe to your automatically-updated feed of events:
</p>

<div class="@container">
  <CalendarFeeds calendar={current_user.calendar} />
</div>

<Modal id="subscribe-to-gcal">
  <SubscribeToGcal calendar={current_user.calendar} />
</Modal>
