<script>
  import { page } from '@inertiajs/svelte'

  import { LinkCopier } from '$lib/components'

  /** @type {{ calendar: import('$types').Calendar }} */
  let { calendar } = $props()

  const gcal_url = `${new URL($page.props.url).origin}/calendars/${calendar.id}/events.ics?auth_token=${$page.props.token}`
</script>

<h3 class="text-2xl font-medium">
  Subscribe with your Google Calendar
</h3>

<LinkCopier link={gcal_url} class="mt-3" />

<div class="text-center">
  <small class="text-gray-600">Do not share this link with anyone.</small>
</div>

<ul class="text-sm list-decimal pl-5 mt-3">
  <li>Copy the link above to your clipboard.</li>
  <li><a href="https://calendar.google.com" target="_blank" class="link">Open Google Calendar</a> on the web (in the mobile app won't work).</li>
  <li>On the left side, choose "Other calendars + > From URL".</li>
  <li>Paste the link you copied from above and click "Add calendar".</li>
</ul>
