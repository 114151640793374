<script>
  import { Modal, Form } from '$lib/components'

  import { page } from '@inertiajs/svelte'

  import GroupChatForm from './GroupChatForm.svelte'

  let { event } = $derived($page.props)

  /** @type {{ entry: import('$lib/types').Entry }} */
  let { entry } = $props()

  /** @type {Modal} */
  let modal
</script>

<Modal bind:this={modal} id="edit-entry-modal-{entry.id}">
  <Form
    action="/entries/{entry.id}"
    method="patch"
    onsuccess={() => modal.hide()}
  >
    <input type="hidden" name="entryable_type" value="GroupChat">

    <h3 class="text-2xl font-semibold">Edit group chat</h3>

    <GroupChatForm groupChat={entry.entryable} />

    <button type="submit" class="btn btn-primary w-full">Save</button>
  </Form>
</Modal>
