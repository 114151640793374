<script>
  let { status, url } = $props()

  const descriptions = {
    503: 'Sorry, we are doing some maintenance. Please check back soon.',
    500: "Something went wrong on our servers.",
    404: "We couldn't find the page you were looking for.",
    403: 'Sorry, you are forbidden from accessing this page.',
  }

  let [badge_bg, text_color] = $derived.by(() => {
    switch (status) {
    case 404:
      return ['bg-blue-900/80', 'text-blue-950']
    case 500:
      return ['bg-emerald-900/80', 'text-emerald-950']
    default:
      return ['bg-emerald-900/80', 'text-emerald-950']
    }
  })
</script>

<svelte:head>
  <title>Error {status}: {descriptions[status]}</title>
</svelte:head>

<img src="/{status}.webp" class="absolute object-cover object-top isolate h-full w-full top-0 left-0 z-[-10]" alt="Error {status}">

<div class="flex justify-center items-center px-4 py-16 md:py-32">
  <div class="bg-white/70 p-12 rounded-box max-w-2xl prose prose-lg {text_color}">
    <p>
      <span class="{badge_bg} px-2.5 py-1 rounded-full text-white">Error {status}</span>
    </p>

    <h1 class="mb-0 text-5xl font-bold">This is embarrassing&hellip;</h1>

    <p>{descriptions[status]}</p>

    <p><a href="/" class="btn btn-outline">Head to the homepage &rarr;</a></p>
  </div>
</div>
