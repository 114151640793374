<script>
  import { Combobox } from 'bits-ui'
  import { fly } from 'svelte/transition'
  import * as chrono from 'chrono-node'

  export let placeholder = "12:00"

  /** @type {string | undefined} */
  export let value

  /** @type {any[]} */
  export let options = []

  /** @type {function(string):any | undefined} */
  export let onselect = undefined

  /** @type {any[]} */
  let typed_options = []

  /** @type {string} */
  export let name

  let class_name = ''
  export { class_name as class }

  $: filtered_options = options.filter((option) => {
    let lower_query = (value || '').toLowerCase()

    if (option) {
      return option.toLowerCase().includes(lower_query) || option.replace(':', '').includes(lower_query)
    }

    return false
  })

  /** @param {number} time */
  const pad_time = time => String(time).padStart(2, '0')
</script>

<Combobox.Root
  bind:inputValue={value}
  preventScroll={false}
  onSelectedChange={(selected_value) => {
    value = selected_value.value
    value && onselect?.(value)
    typed_options = []
  }}>
  <div class="relative">
    <Combobox.Input
      class="input input-bordered w-full {class_name}"
      {placeholder}
      aria-label="Choose a time&hellip;"
      {name}
      {value}
      on:input={(evt) => {
        let value = evt.detail.currentTarget.value

        if (value.match(/\d{3,4}/)) value = value.slice(-4, -2) + ':' + value.slice(-2)

        const [result] = chrono.parse(value)

        const start = result?.start
        if (start && start.isCertain('hour') && start.isCertain('minute')) {
          const hour = Number(start.get('hour')),
                 min = Number(start.get('minute'))

          if (start.isCertain('meridiem')) {
            typed_options = [`${pad_time(hour)}:${pad_time(min)}`]
          } else {
            typed_options = [
              `${pad_time(hour)}:${pad_time(min)}`,
              `${pad_time((hour + 12) % 24)}:${pad_time(min)}`
            ].sort()
          }
        } else {
          typed_options = []
        }
      }}
    />
  </div>

  <Combobox.Content
    class="dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-full max-h-[300px] overflow-auto"
    sideOffset={8}
    transition={fly}
  >
    {#each new Set([...typed_options, ...filtered_options]) as option (option)}
      <Combobox.Item
        class="rounded-btn py-2 px-4 text-sm font-normal data-[highlighted]:bg-base-200"
        value={option}
      >
        <slot {option}>
          {option}
        </slot>
      </Combobox.Item>
    {:else}
      <span class="block px-5 py-2 text-sm text-muted-foreground">
        No results found
      </span>
    {/each}
  </Combobox.Content>
</Combobox.Root>
