<script>
  import { inertia } from '@inertiajs/svelte'
  import { onMount } from 'svelte'
  import { Loadable, Pages, Modal }  from '$lib/components'
  import { store } from '$lib/stores'

  let posts = $state([])

  let { unread_count = $bindable(0) } = $props()

  let active_post = $state()

  let is_modal_open = $state(false)

  let is_loading = $state(false)

  let last_read_time = $store['last_changelog_read'] || 0

  const fetch_changelog = async () => {
    is_loading = true

    const res = await fetch('/updates?limit=3', { headers: { 'Content-Type': 'application/json' } });

    ({ posts } = await res.json());

    unread_count = posts.filter(post => new Date(post.date).getTime() > ($store['last_changelog_read'] || 0)).length

    is_loading = false
  }

  $effect(() => {
    if (is_modal_open && posts.length) {
      $store['last_changelog_read'] = new Date(posts[0].date).getTime()
      unread_count = 0
    }
  })

  onMount(fetch_changelog)
</script>

<Modal
  id="changelog-modal"
  bind:open={ is_modal_open }
>
  <Pages.Body>
    <Pages.Page id="changelog-list">
      <h3 class="text-2xl font-medium">Updates to Occasionly</h3>

      <Loadable loading={ is_loading }>
        <ul class="space-y-3 py-4">
          {#each posts as post}
            <li>
              <!-- svelte-ignore a11y_click_events_have_key_events -->
              <!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
              <label class="group cursor-pointer" onclick={() => active_post = post} for="changelog-update">
                <h3 class="font-medium group-hover:text-emerald-600 transition-colors">
                  {#if new Date(post.date).getTime() > last_read_time}
                    <div class="badge badge-md badge-info">New</div>
                  {/if}

                  { post.title }
                </h3>
              </label>
            </li>
          {/each}
        </ul>
      </Loadable>

      <p class="mt-2 text-center">
        <a class="text-emerald-600 hover:underline" use:inertia href="/updates" onclick={() => is_modal_open = false}>
          Read all the updates &rarr;
        </a>
      </p>
    </Pages.Page>

    <Pages.Page id="changelog-update">
      <div class="max-h-[32rem] update-post">
        {#if active_post}
          {#if active_post.image}
            <div class="aspect-[16/9] mt-3">
              <img src={active_post.image} class="h-full w-full object-cover rounded" alt={active_post.title} />
            </div>
          {/if}

          <h3 class="text-xl font-medium mt-3">{active_post.title}</h3>

          <p class="mt-1 prose prose-sm pb-6">{@html active_post.html}</p>
        {/if}
      </div>
    </Pages.Page>
  </Pages.Body>
</Modal>

<style lang="postcss" global>
  .update-post .prose h2 {
    @apply mt-2 font-medium text-lg;
  }

  .update-post .prose li p {
    @apply m-0;
  }

  .update-post figure .image-link-expand {
    @apply hidden;
  }
</style>
