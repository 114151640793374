<script>
  import { setContext } from 'svelte'
  import { random_id } from '$lib/utilities'

  let {
    children,
    initial_page: user_initial_page = '',
    class: class_name = '',
    ...restProps
  } = $props()

  /** @type {number | undefined} */
  let pages_height = $state();

  let history = $state([user_initial_page].filter(Boolean))

  class Pages {
    id = random_id()
    // initial_page = $state(user_initial_page)
    active_page = $state(user_initial_page)

    get initial_page() {
      return history[0]
    }

    push(page) {
      history.push(page)
      this.active_page = page
    }

    pop() {
      if (history.length < 2) return;

      history.pop()
      this.active_page = history[history.length - 1] || ''
    }
  }

  const pages = new Pages()

  setContext('pages', pages)

  export const reset = () => {
    history = history.slice(0, 1)

    pages.active_page = history[0]
  }

  /** @returns {boolean} */
  export const is_initial = () => pages.active_page === history[0]

  /** @param {string} page */
  export const navigate = (page) => pages.push(page)
</script>

<div
  class="{class_name}"
  style="height: {pages_height ? pages_height + 'px' : 'auto'}; transition-property: height; transition-duration: 300ms;"
  {...restProps}
>
  <div class="pages overflow-x-hidden w-[calc(100%+20px)] mx-[-10px] px-[10px]" bind:clientHeight={pages_height}>
    {@render children()}
  </div>
</div>

<style lang="postcss">
  .pages {
    display: grid;
    align-items: start;
  }

  .pages > :global(.page) {
    grid-area: 1 / 1 / 1 / 1;
    min-width: 0px;
  }
</style>
