<script>
  /** @type {import('$lib/types').Entry<'GroupChat'>} */
  export let entry

  $: group_chat = entry.entryable
</script>

<p class="mt-2">
  {entry.creator.name.split(' ')[0] } created a group chat: <a href="whatsapp://chat?code={ group_chat.whatsapp_invite_code }" class="text-info-content dark:text-info hover:underline break-all">{ group_chat.title }</a>.
</p>

<p class="mt-2">You can join with this link: <a href="https://chat.whatsapp.com/{ group_chat.whatsapp_invite_code }" class="text-info-content dark:text-info hover:underline break-all" target="_blank" rel="noreferrer">https://chat.whatsapp.com/{ group_chat.whatsapp_invite_code }</a>.</p>

<p class="mt-2">
  <em>Joining will share your phone number with everyone in the group.</em>
</p>
