<script>
  import { page } from '@inertiajs/svelte'
  import { isPast } from 'date-fns'
  import { attendance_label } from '$lib/utilities'
  import { Form } from '$lib/components'
  import { social_platforms } from '$lib/config'

  /** @type {import('$lib/types').PageProps<'event' | 'current_attendance'>} */
  let { event, current_attendance } = $derived($page.props)

  /** @type {{
     attendance: import('$lib/types').Attendance,
     onupdate: function,
     ondestroy: function
  }} */
  let { attendance = $bindable(), onupdate, ondestroy } = $props()

  const BUTTON_CLASSES = {
    invited: 'btn-primary',
    going: 'btn-primary',
    cannot_go: 'btn-error',
    payment_required: 'btn-warning',
    maybe: 'btn-warning'
  }

  let is_current_user = $derived(attendance.id === current_attendance?.id)
  let is_past = $derived(isPast(new Date(event.end_time)))

  let active_platforms = $derived(Object.values(social_platforms).filter(({ key }) => attendance.user?.socials[key]))

  /** @type {'You' | 'They'} */
  let article = $derived(is_current_user ? 'You' : 'They')
</script>

<div class="flex h-full flex-col justify-between">
  <div class="grid gap-4">
    <h3 class="text-2xl font-semibold">{attendance.user?.name}</h3>

    {#if active_platforms.length}
      <div class="flex gap-1.5">
        {#each active_platforms as platform}
          <a
            href="{platform.base_url}/{attendance.user.socials[platform.key]}"
            class="btn btn-outline btn-sm btn-square"
            aria-label={platform.title}
            target="_blank"
            rel="noreferrer"
          >
            <span class="{platform.icon} h-4 w-4"></span>
          </a>
        {/each}
      </div>
    {/if}

    <div class="flex justify-between items-start space-x-2">
      <div class="flex-1 h-full grid items-center">
        <p class="font-medium flex-1">
          { attendance_label(attendance, { is_past, article }) }
        </p>

        {#if current_attendance.is_host && !is_current_user}
          <p class="text-sm text-muted">
            Updating the RSVP won't notify them, and we'll add a flag to the guest list saying they didn't update it.
          </p>
        {/if}
      </div>

      {#if current_attendance.is_host || attendance.id == current_attendance.id}
        <div class="relative">
          <Form
            action="/attendances/{attendance.id}"
            method="patch"
            class="w-full"
            onsuccess={() => onupdate()}
          >
            <div class="relative dropdown dropdown-end dropdown-hover">
              <div tabindex="0" role="button" class="btn h-10 min-h-10 {BUTTON_CLASSES[attendance.status]}">
                { attendance_label(attendance, { short: true, is_past, article }) }
                <span class="i-feather-chevron-down h-5 w-5"></span>
              </div>

              <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
              <div tabindex="0" class="pt-2 dropdown-content z-[1]">
                <ul class="p-2 shadow menu bg-base-100 rounded-box border border-base-200">
                  <li>
                    <button type="submit" name="status" value="going">
                      <span class="i-feather-check"></span>
                      { is_past ? 'Went' : 'Going' }
                    </button>
                  </li>

                  <li>
                    <label for="attendee-maybe-page">
                      <span class="i-ion-help-outline"></span>
                      Maybe
                    </label>
                  </li>

                  <li>
                    <button class="whitespace-nowrap" type="submit" name="status" value="cannot_go">
                      <span class="i-feather-x"></span>
                      {is_past ? "Didn't go" : "Not going"}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </Form>
        </div>
      {/if}
    </div>

    {#if attendance.reason}
      <div>
        <p class="font-medium">{article} left this message:</p>

        <blockquote class="text-sm border-l-[3px] pl-3 mt-1">
          {attendance.reason}
        </blockquote>
      </div>
    {/if}

    {#if current_attendance.is_host}
      <div class="flex justify-between items-start space-x-2">
        <div class="flex-1">
          <label class="font-medium flex-1" for="attendance-host-{attendance.id}">Event host</label>

          <p class="text-sm text-muted">
            Hosts can invite others, send event updates and change event details.
          </p>
        </div>

        {#if attendance.is_host}
          <span class="i-feather-check text-success h-6 w-6"></span>
        {:else if attendance.can_host}
          <div class="badge badge-info">Invited</div>
        {:else}
          <label for="attendee-host-page" class="btn btn-outline btn-sm">
            <span class="i-feather-chevrons-up"></span>
            Make host
          </label>
        {/if}
      </div>

      {#if !is_current_user && !(attendance.status === 'going' && event.ticket_price)}
        <div class="flex justify-between items-start space-x-2">
          <div class="flex-1">
            <p class="font-medium flex-1">
              Remove {attendance.user?.name?.split?.(' ')?.[0] || 'attendee'}
            </p>

            <p class="text-sm text-base-content/70">
              Removing them won't notify them.
            </p>
          </div>

          <div class="relative">
            <Form
              action="/attendances/{attendance.id}"
              method="delete"
              class="w-full"
              onsuccess={() => ondestroy()}
            >
              <button type="submit" class="btn btn-sm btn-error">
                Remove
              </button>
            </Form>
          </div>
        </div>
      {/if}

      <Form
        action="/attendances/{attendance.id}"
        method="patch"
        onsuccess={() => onupdate()}
      >

      <label class="form-control w-full">
        <div class="label pb-0.5 pt-0">
          <span class="label-text font-medium text-base">Notes</span>
        </div>

        <textarea
          bind:value={attendance.notes}
          name="notes"
          class="textarea textarea-bordered"
          placeholder="Anything you'd like to note down&hellip;"
        ></textarea>

        <!-- <Errors errors={contact_errors?.name} /> -->
      </label>

        <button type="submit" class="mt-3 w-full btn btn-primary">Save</button>
      </Form>
    {/if}
  </div>
</div>