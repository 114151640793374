/**
 * @param {string} string
 * @param {string} substring
 */
export const highlight = (string, substring) =>
  substring.length > 1
    ? string.replace(new RegExp(escape(substring), 'gi'), (match) => `<strong>${match}</strong>`)
    : string

/** @param {string} string */
const escape = (string) => {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}
