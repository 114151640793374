<script>
  /** @type {?string[]} */
  export let errors

  /** @type {string} */
  let class_name = ''
  export { class_name as class }
</script>

<div class="text-rose-500 text-sm max-h-0 overflow-hidden transition-[max-height] duration-300 {class_name}" class:max-h-20={ errors && errors.length }>
  <ul class="mt-2">
    {#each new Set(errors || []) as error }
      <li>{ @html error }</li>
    {/each}
  </ul>
</div>
