<script>
  import { inertia } from '@inertiajs/svelte'
  import { format } from 'date-fns'

  let { post } = $props()
</script>

<svelte:head>
  <title>{ post.title } • Occasionly updates</title>

  <meta name="title" property="og:title" content="{post.title}" />
  <meta name="type" property="og:type" content="website" />

  {#if post.image}
    <meta name="image" property="og:image" content="https://occasionly.io/images/updates/{post.image}" />
    <meta name="twitter:image" content="https://occasionly.io/images/updates/{post.image}" />
  {:else}
    <meta name="image" property="og:image" content="https://occasionly.io/twitter-summary-image.webp" />
    <meta name="twitter:image" content="https://occasionly.io/twitter-summary-image.webp" />
  {/if}

  <meta name="twitter:title" content="{post.title}" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:site" content="@occasionly_io" />
</svelte:head>

<div class="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 sm:pt-8">
  <a use:inertia href="/updates" class="text-gray-500">&larr; Updates</a>

  <article class="prose mt-3">
    {#if post.image}
      <div class="aspect-[16/9]">
        <img src={post.image} class="h-full w-full object-cover rounded-box" alt={post.title} />
      </div>
    {/if}

    <h1>{post.title}</h1>

    <p class="mt-3 text-xl font-medium text-gray-400">{format(new Date(post.date), 'do MMM yyyy')}</p>

    {@html post.html}
  </article>
</div>
