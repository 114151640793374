<script>
  import { onMount, tick } from 'svelte'
  import { page } from '@inertiajs/svelte'

  import { Form, Modal } from '$lib/components'
  import { notify } from '$lib/utilities'

  import ImageGenerationForm from './ImageGenerationForm.svelte'

  import Cropper from 'cropperjs'
  import 'cropperjs/dist/cropper.css'

  /** @type {{
    src: string
    raw_src: string
    alt: string
    photo_blob_id?: Promise<string>
    class?: string
  }} */
  let {
    src = '',
    raw_src = '',
    alt = '',
    photo_blob_id = $bindable(),
    class: class_name = ''
  } = $props()

  /** @type {(value: string | PromiseLike<string>) => void} */
  let resolve_photo_id = $state()

  /** @type {(reason?: any) => void} */
  let reject_photo_id = $state()

  /** @type {string} */
  let url = $state()

  /** @type {boolean} */
  let is_photo_changed = $state(false)

  /** @type {boolean} */
  let is_dragging_over = $state(false)

  /** @type {boolean} */
  let is_dragging = $state(false)

  /** @type {Modal} */
  let photo_modal = $state()

  /** @type {HTMLImageElement} */
  let image_el

  /** @type {Form} */
  let form_el = $state()

  /** @type {Cropper.Data | null} */
  let crop_data = $state()

  /** @type {number} */
  let image_width = $state()

  /** @type {number} */
  let image_height = $state()

  onMount(() => {
    cropper = new Cropper(image_el, {
      checkCrossOrigin: false,
      checkOrientation: false,
      toggleDragModeOnDblclick: false,
      dragMode: 'move',
      cropBoxResizable: false,
      autoCropArea: 1,
      viewMode: 2,
      aspectRatio: 16 / 9,
      rotatable: false
    })
  })

  /** @param {Event} evt */
  const handle_file_select = (evt) => {
    const [file] = evt.target.files

    if (file && file.type.match(/image.*/)) {
      const file_reader = new FileReader()

      file_reader.onload = function () {
        open_cropper(String(this.result))
      }

      file_reader.readAsDataURL(file)
    } else {
      notify('not_image_file')
    }
  }

  /** @param {string} photo_url */
  export const open_cropper = (photo_url) => {
    cropper.replace(photo_url)
    photo_modal.show()
  }

  /** @type {Cropper} */
  let cropper

  const handle_crop = async () => {
    src = cropper.url
    crop_data = cropper.getData(true)

    const { naturalWidth, naturalHeight } = cropper.getImageData()
    image_width = naturalWidth
    image_height = naturalHeight

    is_photo_changed = true
    photo_modal.hide()

    await tick()

    photo_blob_id = new Promise((resolve, reject) => {
      resolve_photo_id = resolve
      reject_photo_id = reject
    })

    try {
      const is_base64 = /^data:image\/[a-z]+;base64,/.test(src)

      const { signed_id } = await fetch('/blobs', {
        method: 'POST',
        body: JSON.stringify({
          ...(is_base64 ? { base64: src } : { url: src }),
          metadata: { crop: crop_data }
        }),
        headers: {
          'X-CSRF-Token': String($page.props.csrf_token),
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())

      resolve_photo_id(signed_id)
    } catch {
      reject_photo_id()
    }
  }

  /** @param {DragEvent} evt */
  const prevent_default_drop = (evt) => {
    /** @ts-ignore */
    if (evt.target?.type !== 'file') {
      evt.preventDefault()
    }
  }
</script>

<svelte:window
  ondragover={ (evt) => {
    prevent_default_drop(evt)

    is_dragging = true
  }}
  ondragleave={ () => is_dragging = false }
  ondragenter={prevent_default_drop}
  ondropcapture={ (evt) => {
    prevent_default_drop(evt)

    is_dragging = false
    is_dragging_over = false
  }}
/>
  <div
    class="relative z-[2] aspect-[16/9] w-full flex justify-center items-center group transition-all bg-base-200 rounded-box {class_name} overflow-hidden"
    class:is-dragging={is_dragging}
    ondragover={(evt) => {
      evt.preventDefault()
      is_dragging_over = true
    }}
    ondragleave={(evt) => {
      evt.preventDefault()
      is_dragging_over = false
    }}
    role="presentation"
  >
    <input
      type="file"
      name="file"
      accept="image/*"
      class="absolute inset-0 cursor-pointer opacity-0"
      onchange={handle_file_select}
    />

    {#if url}
      <input type="hidden" name="url" value={url} />
    {/if}

    {#if crop_data}
      {#each ['x', 'y', 'width', 'height'] as key}
        <input type="hidden" name="metadata[crop][{key}]" value={crop_data[key]} />
      {/each}
    {/if}

    {#if src}
      <div
        class="absolute pointer-events-none bg-no-repeat h-full w-full rounded-box transition-all"
        style={`
          background-image: url(${src});
          background-size: ${crop_data ? 100 * image_width / crop_data.width : 100}%;
          background-position:
            ${crop_data?.x ? 100 * crop_data.x / (image_width - crop_data.width) : 0}%
            ${crop_data?.y ? 100 * crop_data.y / (image_height - crop_data.height) : 0}%;
        `}
      ></div>
    {/if}

    <div class="text-base-content group-hover:text-emerald-500 relative pointer-events-none flex flex-col justify-center items-center rounded-box inset-0 w-full h-full transition-all {is_photo_changed ? 'opacity-0' : 'opacity-100'} {!!src ? 'bg-black/50' : ''} {is_dragging_over ? 'ring-emerald-500 !text-emerald-500' : 'ring-sky-500'}" class:ring-4={is_dragging}>
      <svg class="h-24 w-auto text-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
        <circle cx="8.5" cy="8.5" r="1.5"></circle>
        <polyline points="21 15 16 10 5 21"></polyline>
      </svg>

      <div class={src ? 'text-white' : 'text-gray-600'}>
        {src ? 'Change photo' : 'Upload a photo'}
      </div>
    </div>
  </div>
<!-- </Form> -->

<ImageGenerationForm
  class="mt-3"
  ongenerate={new_url => {
    url = new_url
    open_cropper(url)
  }}
/>

<Modal id="photo_modal" bind:this={photo_modal} class="!max-w-lg">
  <h3 class="text-2xl font-semibold">Crop the photo</h3>

  <div class="aspect-square mt-3">
    <img
      bind:this={image_el}
      {src}
      {alt}
      class="block max-w-full w-full h-full object-cover"
    />
  </div>

  <button type="button" class="btn btn-primary w-full mt-3" onclick={handle_crop}>
    Done
  </button>
</Modal>

<style lang="postcss">
  img[src=""] { display: none }

  .is-dragging {
    box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0.5);
    @apply z-20;
  }

  ::file-selector-button {
    @apply !hidden
  }
</style>
