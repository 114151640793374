<script>
  import { highlight, random_id } from '$lib/utilities'
  import { Avatar } from '$lib/components'

  /** @type {import('$lib/types').ContactListItem} */
  export let item

  /** @type {string} */
  export let search_query

  /** @type {string[]} */
  export let selected_item_ids

  /** @type {boolean} */
  export let selectable

  /** @type {boolean} */
  export let disabled

  $: is_disabled = item.disabled || disabled

  const id = random_id()
</script>

<li class="flex items-center">
  <slot />

  <label
    for="{id}-item-{item.id}"
    class="py-1 group w-full"
    class:cursor-pointer={!is_disabled && selectable}
  >
    <div class="flex items-center w-full">
      <input
        type="checkbox"
        value={item}
        id="{id}-item-{item.id}"
        checked={selected_item_ids.includes(item.id) || !selectable}
        class="hidden peer"
        disabled={is_disabled || !selectable}
        on:change
        on:change={ ({ target }) => {
          if (target?.checked) {
            selected_item_ids = [...selected_item_ids, item.id]
          } else {
            selected_item_ids = selected_item_ids.filter(id => id !== item.id)
          }
        }}
      />
      <Avatar user={item} class="w-7 h-7 {is_disabled ? `opacity-25` : `group-hover:opacity-100 opacity-60 bg-opacity-0 border peer-checked:opacity-100 peer-checked:bg-opacity-100`}" />

      <p class="select-none ml-2 w-full {selectable ? 'peer-checked:text-emerald-600 peer-checked:[&>*]:text-emerald-600' : ''}" class:text-gray-400={is_disabled}>
        {@html highlight(item.name, search_query)}
      </p>

      {#if selectable}
        <span class="i-feather-check h-5 w-5 flex-shrink-0 transition-colors text-transparent peer-checked:text-emerald-600"></span>
      {/if}
    </div>
  </label>
</li>
