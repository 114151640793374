const DEFAULT_OPTIONS = {
  /** @param {any} item */
  mapFn: (item) => String(item),
  itemLength: 3
}

/**
 * @template T
 * @param {T[]} array
 * @param {object} opts
 * @param {function(T):string} [opts.mapFn]
 * @param {number} [opts.itemLength]
 * @returns {string}
 */

export const arrayToSentence = (array, opts = {}) => {
  const options = { ...DEFAULT_OPTIONS, ...opts }

  let result = array.slice(0, options.itemLength).map(options.mapFn)

  if (array.length > options.itemLength) {
    result.push(`${array.length - options.itemLength} other${array.length > 4 ? 's' : ''}`)
  }

  return new Intl.ListFormat('en').format(result)
}

export const array_to_sentence = arrayToSentence
