<script>
  import { onMount, onDestroy, getContext } from 'svelte'
  import { on } from 'svelte/events'
  import { page, router } from '@inertiajs/svelte'

  /** @type {Omit<import('svelte/elements').HTMLFormAttributes, 'method'> & {
    children: import('svelte').Snippet
    method?: 'post' | 'put' | 'patch' | 'get' | 'delete'
    class?: string
    onsubmit?: function(SubmitEvent):any
    onsuccess?: import('@inertiajs/core').VisitOptions['onSuccess']
    onerror?: import('@inertiajs/core').VisitOptions['onError']
    onfinish?: import('@inertiajs/core').VisitOptions['onFinish']
    oninput?: function():any | import('svelte/elements').FormEventHandler<HTMLFormElement>
    onchange?: import('svelte/elements').FormEventHandler<HTMLFormElement>
    loading?: boolean
    changed?: boolean
    valid?: boolean
    action: string
    bind_keyboard_submission?: boolean
  }} */
  let {
    children,
    method = 'post',
    class: class_name = '',
    onsubmit = undefined,
    onsuccess = undefined,
    onerror = undefined,
    onfinish = undefined,
    oninput = undefined,
    onchange = undefined,
    loading = $bindable(false),
    changed = false,
    valid = $bindable(true),
    action,
    bind_keyboard_submission = false,
    ...restProps
  } = $props()

  if (bind_keyboard_submission) {
    /** @param {KeyboardEvent} evt */
    const handler = (evt) => {
      if (evt.key === 'Enter' && evt.metaKey) {
        form_el.dispatchEvent(new SubmitEvent('submit'))
      }
    }

    const container = getContext('container')

    if (container) {
      $effect(() => {
        if (container.open) {
          const cleanup = on(window, 'keydown', handler)

          return () => cleanup()
        }
      })
    } else {
      const cleanup = on(window, 'keydown', handler)

      onDestroy(cleanup)
    }
  }

  /** @type {string} */
  let original_state = $state('')

  const serialize_form = (form = form_el) => form && Array.from(new FormData(form)).join('-')

  /** @type {import('svelte/elements').FormEventHandler<HTMLFormElement>} event */
  const handle_input = (event) => {
    if (oninput) oninput(event)

    check_validity()
  }

  const check_validity = () => {
    valid = form_el.checkValidity()
    changed = original_state !== serialize_form()
  }

  onMount(() => {
    original_state = serialize_form()

    check_validity()
  })

  /**
   * @private
   * @type {HTMLFormElement}
   */
  let form_el = $state()

  /**
   * @function element
   * @returns {HTMLFormElement}
   * @instance
   */
  export const element = () => form_el

  /**
   * @param {SubmitEvent} event
   * @this {HTMLFormElement}
   */
  async function handle_submit(event) {
    if (!this.checkValidity()) {
      return this.reportValidity()
    }

    event.preventDefault()

    const submitter = /** @type {HTMLButtonElement | HTMLInputElement | null} */ (event.submitter || this.querySelector('[type="submit"]'))

    submitter?.classList.add('is-loading')
    loading = true

    if (onsubmit) await onsubmit(event)

    const data = new FormData(this)

    /** WebKit bug doesn't use submit element's name & value fields */
    if (submitter?.name) data.append(submitter.name, submitter.value)

    router.visit(action, {
      ...(method === 'delete' ? {} : { data }),
      method,
      preserveScroll: true,
      preserveState: true,
      onSuccess: (page) => {
        onsuccess && onsuccess.call(this, page)

        if (method === 'post') this.reset()

        original_state = serialize_form(this)
        changed = false
      },
      onError: (errors) => {
        onerror && onerror(errors)
      },
      onFinish: (visit) => {
        onfinish && onfinish(visit)

        loading = false
        submitter?.classList.remove('is-loading')
      }
    })
  }
</script>

<form
  bind:this={form_el}
  method="POST"
  onsubmit={handle_submit}
  oninput={handle_input}
  {onchange}
  {action}
  class="{class_name}"
  {...restProps}
>
  <input type="hidden" name="authenticity_token" value={$page.props.csrf_token}>

  {#if children}
    {@render children()}
  {/if}
</form>
