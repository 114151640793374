<script>
  import { Transition } from '$lib/components'

  /** @type {any} */
  export let key

  /** @type {boolean} */
  export let forwards = true

  /** @type {number} */
  export let x = 5

  /** @type {number}  */
  export let duration = 200
</script>

<Transition
  {key}
  from={{ duration, delay: 150, x: forwards ? -x : x }}
  to={{ duration, x: forwards ? x : -x }}
>
  <slot />
</Transition>
