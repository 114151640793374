/**
 * @template T
 * @param {T[]} array
 * @param {function(T):string} keyFn — the key function on which to group the array items
 */
export const group_array = (array, keyFn) =>
  array.reduce(
    /**
     * @param {{ [x: string]: any }} obj
     * @param {T} item
     * @returns {{ [key: string]: T[] }}
     */
    (obj, item) => {
      const key = keyFn(item)

      /** @type {{ [key: string]: T[] }} */
      return {
        ...obj,
        [key]: [...(obj[key] || []), item]
      }
    },
    {}
  )
