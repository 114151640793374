<script>
  import { page, inertia } from '@inertiajs/svelte'
</script>

<div class="pt-8 sm:pt-16 md:pt-24">
  <div class="mx-auto max-w-7xl">
    <div class="mx-auto max-w-3xl text-center">
      <h1 class="text-4xl font-semibold sm:text-5xl md:text-6xl lg:text-7xl">
        <span class="title-gradient">
          Easily host events<br />
          with your people
        </span>
      </h1>
      <p class="mt-6 text-lg leading-8 text-base-content sm:text-xl">
        Meet your people where they are — no apps, no downloads, just<br class="max-sm:hidden" />
        seamless invitations via WhatsApp, Signal, Telegram or email.
      </p>

      <div class="mt-6 flex items-center justify-center gap-x-3">
        <div>
          <a use:inertia href="/events/new" for="create-event-modal" class="btn btn-primary text-base">
            Create your event
          </a>
        </div>

        <a use:inertia href="/events/{$page.props.config.demo_event_id}" class="btn btn-ghost text-base">
          See a demo <span aria-hidden="true">→</span>
        </a>
      </div>
    </div>

    <div id="example-event" class="mx-auto mt-4 max-w-xl p-3">
      <div class="rounded-md bg-base-100 dark:bg-base-300 p-2 shadow-lg dark:shadow-white/10">
        <img
          src="/example_event_page.webp?v=2"
          height="1408"
          width="1280"
          alt="Example event page"
          class="dark:hidden"
        />

        <img
          src="/example_event_page-dark.webp?v=2"
          height="1408"
          width="1280"
          alt="Example event page"
          class="hidden dark:inline"
        />
      </div>
    </div>
  </div>
</div>


<style lang="postcss">
  #example-event {
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

  .title-gradient {
    @apply bg-gradient-to-tr from-black via-slate-800 to-gray-500 dark:from-white dark:via-slate-300 dark:to-gray-300 box-decoration-clone bg-clip-text text-transparent;
  }
</style>
