import { writable } from 'svelte/store'

const DEFAULT_TIME_ZONE = 'Europe/London'

let current_time_zone = DEFAULT_TIME_ZONE

if (typeof window !== 'undefined') {
  current_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone || DEFAULT_TIME_ZONE
}

/** @param {string} tz */
export const time_zone = writable(current_time_zone)

export { current_time_zone }
