<script>
  import { flip } from 'svelte/animate'
  import { scale } from 'svelte/transition'

  import { page } from '@inertiajs/svelte'
  import { Avatar, Form } from '$lib/components'
  import { group_array } from '$lib/utilities'

  /** @type {import('$types').PageProps<'event' | 'current_user'>} */
  let { event, current_user } = $derived($page.props)

  let { entry, class: class_name = '' } = $props()

  /** @type {HTMLInputElement} */
  let reaction_input_el

  let reaction_tooltip = $state()

  const reset_reaction = () => {
    reaction_tooltip = undefined
    reaction_input_el.value = ""
  }

  const EMOJI_REACTIONS = ['👍', '👎', '😄', '🎉', '😕', '❤️', '🚀', '👀']

  let reaction_entries = $derived(Object.entries(group_array(entry.reactions, (reaction) => reaction.text)))
</script>

<svelte:window
  onkeydowncapture={ (evt) => {
    if (reaction_tooltip && evt.key === 'Escape') {
      evt.stopPropagation()

      reaction_tooltip = undefined
    }
  }}

  onclick={ (evt) => {
    if (evt.target?.name !== `${entry.id}-reactions` && !document.getElementById(`${entry.id}-create-reaction`)?.contains(evt.target)) reaction_tooltip = undefined
  }}
/>

<ul class="flex flex-wrap -m-0.5 {class_name}">
  <li class="flex items-center m-0.5 relative">

    <div class="dropdown dropdown-top">
      <div tabindex="0" role="button" class="reaction-list-item">
        <span class="i-ion-happy-outline h-5 w-5"></span>
      </div>

      <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
      <div tabindex="0" class="mb-2 dropdown-content z-[1] bg-base-100 shadow rounded-box p-2 rounded-bl-none">
        <Form action="/reactions" onsuccess={reset_reaction} class="flex">
          <input type="hidden" name="entry_id" value={entry.id}>

          {#each EMOJI_REACTIONS as emoji}
            <button type="submit" name="text" value={emoji} class="btn btn-square min-h-7 h-7 w-7 btn-ghost">{emoji}</button>
          {/each}
        </Form>

        <Form action="/reactions" onsuccess={reset_reaction}>
          <input type="hidden" name="entry_id" value={entry.id}>
          <div class="relative mt-1">
            <input
              bind:this={reaction_input_el}
              type="text"
              name="text"
              required
              placeholder="Your reaction"
              class="input input-sm input-bordered w-full rounded-bl-none"
              maxlength={16}
              minlength={1}
            />

            <div class="flex absolute end-1 top-1/2 -translate-y-1/2">
              <button aria-label="Submit" type="submit" class="btn btn-square btn-xs btn-ghost">
                <span class="i-feather-check text-emerald-500"></span>
              </button>

              <button aria-label="Cancel" type="button" class="btn btn-square btn-xs btn-ghost" onclick={() => document.activeElement?.blur()}>
                <span class="i-feather-x text-rose-500"></span>
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </li>

  {#each reaction_entries as [text, reactions] (text)}
    {@const user_reaction = reactions.find(reaction => reaction.user.id === current_user?.id) || reactions[0] }

    <li class="relative m-0.5" in:scale={{ delay: 100, start: 0.8 }} out:scale animate:flip={{ duration: 250 }}>
      <div class="dropdown dropdown-top flex justify-center">
        <div tabindex="0" role="button" class="reaction-list-item">
          <Avatar user={user_reaction.user} class="h-5 w-5" />

          <span class="text-sm leading-none inline-block ml-1 pr-[4px]">{text} {#if reactions.length > 1}<span class="text-gray-400 font-light">{reactions.length}</span>{/if}</span>
        </div>

        {#if user_reaction.user.id === current_user?.id}
          <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
          <div tabindex="0" class="mb-2 dropdown-content z-[1] bg-base-100 shadow rounded-btn p-1 ">
            <Form action="/reactions/{user_reaction.id}" method="delete">
              <button class="btn btn-sm btn-ghost px-2 font-medium">
                <p class="flex items-center">
                  Delete

                  <span class="i-ion-close-circle-outline text-rose-500 ml-1 h-4 w-4"></span>
                </p>
              </button>
            </Form>
          </div>
        {:else}
          <Form action="/events/{event.id}/entries/{entry.id}/reactions" onsuccess={reset_reaction} class="absolute inset-0">
            <button aria-label="Submit" type="submit" name="text" value={text} class="w-full h-full"></button>
          </Form>
        {/if}
      </div>
    </li>
  {/each}
</ul>

<style lang="postcss">
  .reaction-list-item {
    @apply relative p-1 rounded-full bg-base-100 border flex items-center border-base-content/10;
  }
</style>
