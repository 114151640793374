<script>
  import VirtualList from 'svelte-tiny-virtual-list';
  import { Combobox } from 'bits-ui'
  import { fly } from 'svelte/transition'
  import { random_id } from '$lib/utilities'
  import { getTimeZones } from '@vvo/tzdb'

  const time_zones = getTimeZones()

  /** @type {string} */
  export let value

  let query = ''

  const time_zones_hash = time_zones.reduce((obj, zone) => ({
    ...obj,
    [zone.name]: zone
  }), {})

  /** @param {number} time */
  const pad_time = time => String(time).padStart(2, '0')

  /** @param {string} time_zone */
  const gmt_offset = (time_zone) => {
    const gmt_offset_hours = time_zones_hash[time_zone].currentTimeOffsetInMinutes / 60

    return `GMT${ Math.sign(gmt_offset_hours) < 0 ? '-' : '+' }${ pad_time(Math.abs(gmt_offset_hours)) }:00`
  }

  /** @type {any[]} */
  let options = time_zones.map(zone => zone.mainCities.map(city => ({
    id: random_id(),
    name: `${city}, ${zone.countryName}`,
    zone: zone.name,
  }))).flat()

  $: filtered_options = options.filter((option) => {
    let lower_query = query.toLowerCase()

    return option.zone.toLowerCase().includes(lower_query) || option.name.toLowerCase().includes(lower_query)
  })
</script>

<Combobox.Root
  items={filtered_options}
  bind:inputValue={query}
  preventScroll={false}
  onSelectedChange={(selected_value) => {
    value = selected_value.value
    queueMicrotask(() => query = '')
  }}>
  <div class="relative">
    <Combobox.Input
      class="input input-bordered w-full select-all"
      placeholder="Start typing&hellip;"
      aria-label="Start typing&hellip;"
      name="time_zone"
      bind:value
    />
    <Combobox.Label />
  </div>

  <Combobox.Content
    class="dropdown-content z-[999] p-2 shadow bg-base-100 rounded-box w-full border border-base-200"
    sideOffset={8}
    transition={fly}
  >
    {#if !filtered_options.length}
      <span class="block px-5 py-2 text-sm text-muted-foreground">
        No results found
      </span>
    {/if}

    <VirtualList
      height={300}
      width="100%"
      itemCount={filtered_options.length}
      itemSize={36}
      getKey={index => filtered_options[index].id}
    >
      <Combobox.Item
        slot="item"
        let:index
        let:style
        {style}
        value={filtered_options[index].zone}
        class="rounded-btn py-2 px-4 text-sm font-normal data-[highlighted]:bg-base-200 w-full flex justify-between items-center"
      >
        <span class="truncate">{filtered_options[index].name}</span>

        <span class="text-xs text-gray-400 whitespace-nowrap">({gmt_offset(filtered_options[index].zone)})</span>
      </Combobox.Item>
    </VirtualList>

  </Combobox.Content>
</Combobox.Root>
