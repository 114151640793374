<script>
  let class_name = ''
  export { class_name as class }

  /** @type {import('$lib/types').Notification['statuses']} */
  export let statuses = {}
</script>

<div class="relative tooltip-hover {class_name}">
  {#if statuses.error_at}
    <span class="i-ion-alert-circle-outline w-4 h-4 text-rose-500"></span>
  {:else if statuses.read_at || statuses.device_at}
    <span class="i-ion-checkmark-done w-4 h-4 { statuses.read_at ? 'text-sky-500' : 'text-gray-500' }"></span>

    <!-- <Tooltip text="Read 12:25" /> -->
  {:else if statuses.server_at}
    <span class="i-ion-checkmark w-4 h-4 text-gray-500"></span>
  {:else if statuses.pending_at}
    <span class="i-ion-time-outline w-4 h-4 text-gray-500"></span>
  {:else}
    <span class="i-ion-help-outline w-4 h-4 text-gray-500"></span>
  {/if}
</div>
