<script>
  import { page } from '@inertiajs/svelte'
  import { Form, Errors } from '$lib/components'

  let identifier = $state(''),
            name = $state('')

  /** @type {{ onsuccess: function():any }} */
  let { onsuccess } = $props()
</script>

<Form
  action="/contacts"
  {onsuccess}
>
  <h2 class="text-lg font-semibold">Add a new contact</h2>
  <p class="mt-2">
    Enter their name and email, WhatsApp, Signal or Telegram number.
  </p>

  <label class="form-control w-full">
    <div class="label pb-0.5">
      <span class="label-text text-muted">Name</span>
    </div>

    <input
      class="input input-bordered w-full"
      placeholder="Giacomo Casanova"
      name="name"
      required
      bind:value={name}
    />

    <Errors errors={$page.props.errors?.name} />
  </label>

  <label class="form-control w-full">
    <div class="label pb-0.5">
      <span class="label-text text-muted">Email or phone number</span>
    </div>

    <input
      class="input input-bordered w-full"
      placeholder="you@example.com"
      name="identifier"
      required
      bind:value={identifier}
    />

    <Errors errors={$page.props.errors?.['notification_channels.identifier'] } />
  </label>

  <button disabled={!identifier.length || !name.length} class="w-full mt-3 btn btn-primary">
    Create contact
  </button>
</Form>
