<script>
  import { EventItem, EventInvitation, FeaturedEvent } from '$lib/components'
  import { usePoll, inertia, router } from '@inertiajs/svelte'
  import { debounce } from '$lib/utilities'

  import { addDays, formatDistanceToNowStrict, parseJSON, isBefore, isAfter } from 'date-fns'

  const STATUS_FILTERS = [
    {
      title: "Going",
      key: "going",
      class: 'checkbox-primary'
    },
    {
      title: "Maybe",
      key: "maybe",
      class: 'checkbox-warning'
    },
    {
      title: "Cannot go",
      key: "cannot_go",
      class: 'checkbox-error'
    }
  ]

  /** @type {{
    page: number
    total: number
    events: import('$types').Event[]
    limit: number
    featured_event: import('$types').Event
    invitations: import('$types').Event[]
    current_user: import('$types').User
    status: string[]
    host: boolean
    url: string
  }} */
  let {
    events,
    page: pagination_page = 1,
    total,
    limit,
    featured_event,
    invitations,
    current_user,
    status,
    host,
    url: current_url
  } = $props()

  let statuses = $state(status),
        is_host = $state(host)

  const filters_url = (page = undefined) => {
    let url = new URL(String(current_url))

    if (page)
      url.searchParams.set('page', String(page))

    url.searchParams.delete('status[]')
    url.searchParams.delete('past')
    url.searchParams.delete('host')

    if (statuses.length) {
      for (let status of statuses) {
        url.searchParams.append('status[]', status)
      }
    } else {
      url.searchParams.append('status[]', '')
    }

    if (is_host) {
      url.searchParams.set('host', 'true')
    }

    return decodeURIComponent(url.pathname + url.search)
  }

  const filter_statuses = debounce((evt) => {
    let label = evt.target.closest('label')
    label.classList.add('is-loading')

    router.visit(filters_url(), {
      preserveScroll: true,
      preserveState: true,
      onFinish: () => label.classList.remove('is-loading')
    })
  }, 500)

  const paginate = (evt, page) => {
    evt.target.classList.add('is-loading')

    router.visit(filters_url(page), {
      preserveScroll: true,
      preserveState: true,
      onFinish: () => evt.target.classList.remove('is-loading')
    })
  }

  let current_attendance = $derived(featured_event && featured_event.attendances.find(({ user_id }) => user_id === current_user.id))

  /** @param {string} string */
  const capitalize = (string) => string[0].toUpperCase() + string.slice(1)

  let is_new_loading = $state(false)

  usePoll(5000)
</script>

<svelte:head>
  <title>Events • Occasionly</title>
</svelte:head>

<svelte:window
  onkeydown={(evt) => {
    const is_input = ['INPUT', 'TEXTAREA'].includes(evt.target?.tagName) && !['checkbox', 'radio'].includes(evt.target?.type)

    if (!is_input && !(evt.ctrlKey || evt.metaKey)) {
      const key = evt.key.toLowerCase()

      switch (key) {
      case 'n':
        return router.visit(`/events/new`, {
          onStart: () => is_new_loading = true,
          onFinish: () => is_new_loading = false
        })
      }
    }
  }}
/>

<div class="grid md:grid-cols-[8fr,5fr] gap-x-8 gap-y-3 md:gap-y-5">
  <div class="md:col-span-2">
    <div class="flex justify-between">
      <h1 class="text-3xl md:text-2xl font-semibold">
        {#if featured_event}
          {@const is_between = isAfter(new Date(), parseJSON(featured_event.start_time)) && isBefore(new Date(), parseJSON(featured_event.end_time))}
          {#if is_between}
            Happening now
          {:else}
            {
              capitalize(
                formatDistanceToNowStrict(
                  parseJSON(featured_event.start_time),
                  { addSuffix: true, roundingMethod: 'ceil' }
                )
              )
            }
          {/if}
        {:else}
          Upcoming
        {/if}
      </h1>

      <div class="flex join shadow-md shadow-accent/20">
        <a use:inertia href="/events/new" class="btn btn-outline btn-accent h-10 min-h-10 join-item">
          {#if is_new_loading}
            <span class="loading loading-spinner loading-sm"></span>
          {/if}
          <span class="i-feather-plus h-5 w-5 md:hidden"></span>
          New <span class="max-md:hidden">event</span>

          <kbd class="kbd kbd-sm font-normal border-accent/50 -mr-1.5">n</kbd>
        </a>

        <label for="generate-event-modal" class="btn btn-accent btn-outline h-10 min-h-10 join-item max-md:w-10">
          <span class="i-heroicons-sparkles h-5 w-5"></span>

          <kbd class="kbd kbd-sm font-normal border-accent/50 -mr-1.5">c</kbd>
        </label>
      </div>
    </div>
  </div>

  <div class="md:col-span-2 relative z-10">
    {#if featured_event}
      <FeaturedEvent event={featured_event} {current_attendance} />
    {:else if invitations.length}
      <ul class="divide-y-px md:col-span-2 mx-auto md:max-w-2xl">
        {#each invitations as event (event.id)}
          <EventItem {event} />
        {/each}
      </ul>
    {:else}
      <div class="max-w-3xl mx-auto">
        {@render no_events_state()}
      </div>
    {/if}
  </div>

  {#if featured_event}
    <div class="max-md:mt-8 {invitations.length ? 'max-md:order-last' : 'md:col-span-2 mx-auto md:max-w-2xl' }">
      <div class="flex justify-between">
        <h2 class="text-2xl font-semibold">Upcoming</h2>

        <div class="flex gap-2">
          {@render pagination_buttons()}

          {@render filter_select()}
        </div>
      </div>

      {#if events.length}
        <ul class="divide-y-px">
          {#each events as event (event.id)}
            <EventItem {event} />
          {/each}
        </ul>
      {:else}
        {@render no_events_state()}
      {/if}
    </div>
  {/if}

  {#if featured_event && invitations.length}
    <div class="max-md:mt-8">
      <h2 class="text-2xl font-semibold">Invitations</h2>

      <ul class="divide-y-px mt-1">
        {#each invitations as event (event.id)}
          <EventInvitation {event} />
        {/each}
      </ul>
    </div>
  {/if}
</div>

{#snippet no_events_state()}
  {@const first_name = current_user.name?.split?.(' ')?.[0]}

  <div class="flex flex-col items-center py-6 md:py-12 gap-4">
    <p>We couldn't find any events. Why not create one?</p>

    <a use:inertia href="/events/new" class="btn btn-outline btn-compact shadow">Create event</a>

    <div class="pointer-events-none relative w-full">
      <EventItem event={{
        title: `${ first_name ? first_name + "'s" : 'Your'  } fantabulous event`,
        description: `Join us for an evening of entertainment and enjoyment! We'll have a variety of activities to participate in, from group games to individual challenges. There will also be a selection of delicious food and drinks to enjoy. We promise a night of laughter, good vibes, and great company. Don't miss out on this unforgettable event!`,
        start_time: addDays(new Date(), 7).toISOString(),
        photo_url: '/event_example_image.webp?v=1',
        location: { formatted_address: 'Fields of Cormallen' }
      }} />

      <div class="absolute inset-x-0 inset-y-0 bg-gradient-to-b from-base-100/10  to-base-100 dark:to-base-300"></div>
    </div>
  </div>
{/snippet}

{#snippet filter_select()}
  <div class="relative dropdown dropdown-end dropdown-hover">
    <div tabindex="0" role="button" class="btn btn-outline btn-sm flex-shrink-0 indicator">
      Filter <span class="i-feather-chevron-down"></span>

      <span class="indicator-item badge bg-white shadow shadow-accent/75 badge-sm border-transparent h-4 w-4">
        <span class="text-accent">{statuses.length + (is_host ? 1 : 0)}</span>
      </span>
    </div>

    <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
    <div tabindex="0" class="dropdown-content pt-2 z-[1]">
      <ul tabindex="0" class="p-2 shadow-md menu bg-base-100 rounded-box w-40 border border-base-200">
        {#each STATUS_FILTERS as filter}
          <li>
            <label for="{filter.key}-filter">
              <input
                id="{filter.key}-filter"
                name="filters"
                value={filter.key}
                type="checkbox"
                bind:group={statuses}
                checked={status.includes(filter.key)}
                onchange={filter_statuses}
                class="i- checkbox checkbox-sm i- {filter.class}"
              />
              {filter.title}
            </label>
          </li>
        {/each}

        <li>
          <label for="host-filter">
            <input
              id="host-filter"
              name="filters"
              value="host"
              type="checkbox"
              bind:checked={is_host}
              onchange={filter_statuses}
              class="checkbox checkbox-sm i-"
            />
            Hosting
          </label>
        </li>
      </ul>
    </div>
  </div>
{/snippet}

{#snippet pagination_buttons()}
  <div class="flex items-center gap-1.5">
    <p class="text-zinc-500 text-sm max-sm:hidden"><strong>{ (pagination_page - 1) * limit + 1 }</strong> to <strong>{ Math.min(pagination_page * limit, total) }</strong> of <strong>{total}</strong></p>

    <div class="flex shadow join">
      <button
        onclick={(evt) => {
          paginate(evt, pagination_page - 1)
        }}
        class="btn btn-sm join-item btn-square {pagination_page <= 1 ? 'btn-disabled' : 'btn-outline'}"
        aria-label="Previous"
      >
        <span class="i-feather-chevron-left h-5 w-5 pointer-events-none"></span>
      </button>

      <button
        onclick={(evt) => {
          paginate(evt, pagination_page + 1)
        }}
        class="btn btn-sm join-item btn-square {pagination_page >= Math.ceil(total / limit) ? 'btn-disabled' : 'btn-outline'}"
        aria-label="Next"
      >
        <span class="i-feather-chevron-right h-5 w-5 pointer-events-none"></span>
      </button>
    </div>
  </div>
{/snippet}
