<script>
  /** @type {import('$lib/types').Event} */
  export let event

  $: hosts = event.hosts.map(({ name }) => (name || '').split(' ')[0])

  const full_stop = event.tip_reason.match(/[\?\.\!]$/) ? '' : '.'

  export let name = ''

  export let value = ''
</script>


<div class="mt-2">
  <label for="tip_amount" class="text-sm text-gray-600">Add an optional tip:</label>

  <div class="relative mt-1">
    <input
      type="number"
      class="input input-bordered w-full pl-6 text-base"
      id="tip_amount"
      placeholder="0.00"
      min="0"
      max={event.ticket_price / 100}
      {name}
      on:input
      bind:value
    />

    <div class="absolute inset-y-0 flex items-center ml-3">
      <span class="text-gray-400 text-base -mb-px">£</span>
    </div>
  </div>

  <p class="text-sm text-gray-600 mt-1">{ new Intl.ListFormat('en').format(hosts) } gave this reason: <strong class="font-semibold">{event.tip_reason}</strong>{full_stop}</p>
</div>