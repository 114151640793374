<script>
  /** @type {{ children: import('svelte').Snippet<[notify]>, class?: string }} */
  let { children, class: class_name = '' } = $props()

  let text = $state('')
  let open = $state(false)

  /** @param {string} new_text */
  const notify = (new_text) => {
    text = new_text
    open = true
  }
</script>

<div
  role="status"
  class="dropdown dropdown-top dropdown-center w-full {class_name} {open && 'dropdown-hover'}"
  onmouseleave={() => open = false}
>
  {@render children(notify)}

  <div class="dropdown-content menu bg-base-100 rounded-btn z-[1000] px-2 py-1 shadow-md border border-base-200 mb-1.5">
    {text}
  </div>
</div>
