<script>
  import { page } from '@inertiajs/svelte'
  import { Form } from '$lib/components'

  let class_name = ''
  export { class_name as class }

  export let price_id = $page.props.config.pricing.monthly

  export let return_url = $page.props.url.toString()
  export let cancel_url = $page.props.url.toString()

  // $: return_url = $page.url.toString() + ($page.url.search ? '&' : '?') + 'export'
</script>

<Form action="/stripe/checkout_sessions">
  <input type="hidden" name="price_id" value={price_id} />
  <input type="hidden" name="return_url" value={return_url} />
  <input type="hidden" name="cancel_url" value={cancel_url} />

  <button
    type="submit"
    class="btn btn-primary text-base {class_name}"
  >Get started today</button>
</Form>