<script>
  import { generateColor } from '$lib/utilities'

  /** @type {{ [key: string]: any, name?: string, photo_url?: string }} */
  export let user = {}

  /** @type {?string} */
  export let color = null

  let class_name = ''
  export { class_name as class }

  export let textClass = ''

  $: _color = color || generateColor(user?.name || '')
</script>

<div
  class="flex-shrink-0 rounded-full relative inline-block text-{_color}-900 border-{_color}-300 bg-{_color}-300 {class_name}"
  {...$$restProps}
>
  <slot>
    <div class="absolute inset-0 flex items-center justify-center text-xs">
      {#if user?.name }
        { @const initials = user.name.match(/[^\(]*/i).toString().split(' ')?.map(name => name[0])?.slice(0, 2)?.join('') || '' }

        <h3 class="font-mono font-medium select-none uppercase {textClass}">{initials}</h3>
      {:else}
        <span class="i-ion-person w-[60%] h-[60%]"></span>
      {/if}
    </div>
  </slot>

  {#if user?.photo_url}
    <img src="{user.photo_url}&width=50" alt={user.name} class="absolute inset-0 rounded-full" />
  {/if}
</div>
