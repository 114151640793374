<script>
  import { page } from '@inertiajs/svelte'
  import { Form } from '$lib/components'

  /** @type {{
    attendance: import('$types').Attendance
    class: string
    action: string
    bind_keyboard_submission?: boolean
    method: 'post' | 'put' | 'patch' | 'get' | 'delete'
   }} */
  let { attendance, class: class_name = '', action, method, bind_keyboard_submission, ...restProps } = $props()

  let is_current_user = $derived(attendance ? attendance.user_id === $page.props.current_user.id : true)

  let value = $state(attendance?.probability || 50.0)
</script>

<Form {action} {method} {bind_keyboard_submission} {...restProps} class={class_name}>
  <div>
    <h3 class="text-2xl font-medium">
      How likely are { is_current_user ? 'you' : 'they' } to go?
    </h3>

    <div class="mt-6">
      <div class="flex flex-col @sm:flex-row @sm:space-x-3">
        <div class="flex-1">
          <div class="flex [&>*]:flex-1 text-xs text-muted">
            <span>Not going</span>
            <span class="text-center">50/50</span>
            <span class="text-right">Going</span>
          </div>

          <input
            type="range"
            min="0"
            max="100"
            name="probability"
            bind:value
            class="w-full appearance-none h-2 rounded-full bg-base-300"
          />
        </div>

        <div class="flex-1 relative mt-3">
          <input
            type="number"
            min="0"
            max="100"
            step="any"
            name="probability"
            class="maybe-input w-full input input-bordered text-center"
            bind:value
          />

          <div class="absolute inset-0 left-[1.25rem] flex items-center justify-center pointer-events-none">
            <span class="invisible">{value}</span>
            <span class="i-feather-percent text-neutral/50 h-4 w-4 ml-1"></span>
          </div>
        </div>
      </div>

      {#if is_current_user}
        <label class="form-control mt-1">
          <div class="label pb-0.5">
            <span class="label-text">Share an optional reason why:</span>
          </div>

          <!-- errors={ $page.form?.errors?.visible_location } -->

          <input
            type="text"
            id="maybe-reason"
            name="reason"
            value={ attendance?.status === 'maybe' ? attendance.reason : '' }
            class="input input-bordered w-full"
            placeholder="I'm away on that date."
          />
        </label>
      {/if}
    </div>
  </div>

  <div class="relative mt-3">
    <button
      type="submit"
      name="status"
      value="maybe"
      class="w-full btn btn-warning"
    >
      Submit
    </button>

    {#if bind_keyboard_submission}
      <div class="absolute flex items-center inset-y-0 right-3 gap-1">
        <kbd class="kbd kbd-sm kbd-warning">⌘</kbd>
        <kbd class="kbd kbd-sm kbd-warning">⏎</kbd>
      </div>
    {/if}
  </div>
</Form>

<style lang="postcss">
  input[type="range"]::-webkit-slider-thumb {
    @apply appearance-none rounded-full;
  }

  input[type="range"]::-webkit-slider-thumb {
    @apply bg-secondary h-6 w-6 cursor-pointer;
  }

  input[type="range"]::-moz-range-thumb {
    @apply bg-secondary h-6 w-6 cursor-pointer;
  }

  /* Chrome, Safari, Edge, Opera */
 /*  :global(.maybe-input::-webkit-outer-spin-button),
  :global(.maybe-input::-webkit-inner-spin-button) {
    -webkit-appearance: none;
    margin: 0;
  } */

  /* Firefox */
  :global(.maybe-input[type=number]) {
    -moz-appearance: textfield;
  }
</style>
