<script>
  import { page } from '@inertiajs/svelte'

  /** @type {{ calendar: import('$types').Calendar }} */
  let { calendar } = $props()

  const webcal_url = `webcal://${new URL($page.props.url).host}/calendars/${calendar.id}/events.ics?auth_token=${
    $page.props.token
  }`
</script>

<div class="mt-3 space-y-1.5 flex flex-col items-start @sm:flex-row @sm:space-y-0 @sm:justify-around">
  <a
    class="btn btn-ghost text-base @sm:justify-center"
    href="{webcal_url}"
  >
    <img
      alt="Apple Calendar"
      src="/apple_calendar.webp"
      class="h-5 w-5"
    />

    <span>Apple <span class="@sm:hidden @lg:inline">Calendar</span></span>
  </a>

  <a
    target="_blank"
    class="btn btn-ghost text-base"
    href="https://outlook.live.com/calendar/0/addcalendar?name=Occasionly%20events&url={webcal_url}"
  >
    <img
      alt="Microsoft Outlook"
      src="/microsoft_outlook.webp"
      class="h-5 w-5"
    />

    <span><span class="@sm:hidden @lg:inline">Microsoft</span> Outlook</span>
  </a>

  <label
    class="btn btn-ghost text-base"
    for="subscribe-to-gcal"
  >
    <img
      alt="Google Calendar"
      src="/google_calendar.webp"
      class="h-5 w-5"
    />

    <span>Google <span class="@sm:hidden @lg:inline">Calendar</span></span>
  </label>
</div>

