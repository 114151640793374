<script>
  import { Form, WhatsAppMarkdown, Errors, Modal } from '$lib/components'
  import { random_id } from '$lib/utilities'
  import { attendance_states, notification_channels } from '$lib/config'
  import { page } from '@inertiajs/svelte'

  /** @type {import('$types').PageProps<'event' | 'current_user'>} */
  let { current_user, event } = $derived($page.props)

  /** @type {{ entry: import('$types').Entry, open: boolean }} */
  let { entry, open = $bindable(false), ...restProps } = $props()

  /** @type {import('$lib/types').Attendance['status'][]} */
  let selected_statuses = $state(['going'])

  let active_states = $derived(Object.values(attendance_states(event)).filter(({ key }) => event.attendee_stats[key]))

  const entry_config = {
    GroupChat: {
      title: 'Group chat',
      text_key: 'title'
    },
    Post: {
      title: 'Post',
      text_key: 'text'
    },
    Poll: {
      title: 'Poll',
      text_key: 'title'
    }
  }

  const id = random_id()
</script>

<!-- <svelte:window
  on:keydown|capture={ (evt) => {
    if (modal_open && evt.key === 'Escape' && (pages.getIndex() > 0 || evt.repeat)) {
      evt.stopPropagation()

      pages.goBack()
    }
  }}
/> -->

<Modal
  bind:open
  class="overflow-visible"
  {...restProps}
>
  {#if entry}
    <h3 class="text-2xl font-semibold">Broadcast {entry_config[entry.entryable_type].title.toLowerCase()} <span class="text-gray-400">(optional)</span></h3>

    <WhatsAppMarkdown
      class="truncate whitespace-pre-wrap line-clamp-2 border-l-2 pl-2 py-1 mt-2"
      text={ entry.entryable[entry_config[entry.entryable_type].text_key] }
    />

    <p class="mt-3 text-sm text-muted">Notify the attendees who are&hellip;</p>

    <Form
      action="/broadcasts"
      onsuccess={(page) => {
        console.log(page)
        if (page.props.broadcast?.id) {
          // open = false
        }
      }}
    >
      <input type="hidden" name="entry_id" value={entry.id}>
      <div class="flex flex-col -mx-2 -mb-0.5 mt-1">
        <div class="relative mx-2 my-1 text-sm flex items-center flex-shrink-0 space-x-2 text-gray-400">
          <input
            type="checkbox"
            disabled
            checked
            class="checkbox checkbox-sm"
          />

          <!-- appearance-none rounded transition-all checked:bg-gray-200 checked:border-gray-300 checked:after:bg-gray-600 w-5 h-5 border -->

          <p class="select-none">Hosts ({ event.attendances.filter(({ is_host }) => is_host).length })</p>
        </div>

        {#each active_states as state}
          {@const count = event.attendee_stats[state.key] }

          <div class="relative mx-2 my-1 text-sm flex items-center flex-shrink-0 space-x-2" class:text-gray-400={ count === 0 }>
            <input
              type="checkbox"
              id="{ id }-{ state.key }"
              name="notifiable_attendee_statuses[]"
              value={ state.key }
              disabled={ count === 0}
              bind:group={selected_statuses}
              class="checkbox checkbox-sm checked:checkbox-primary"
            />

            <label class="select-none" for="{id}-{state.key}">{state.title} ({ count })</label>
          </div>
        {/each}
      </div>

      <Errors errors={$page.props.errors?.base} />

      <div class="flex w-full mt-3 join">
        <button
          class="flex-1 btn btn-primary join-item"
          type="submit"
          disabled={!selected_statuses.length}
        >Send</button>

        <div class="relative dropdown dropdown-end">
          <div tabindex="0" role="button" class="btn join-item">
            Send test
            <span class="i-feather-chevron-down"></span>
          </div>

          <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
          <ul tabindex="0" class="mt-2 p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box">

            {#each current_user?.notification_channels || [] as channel}
              {@const config = notification_channels[channel.name]}

              <li>
                <button class="items-start" type="submit" name="test_channel_id" value={channel.id}>
                  <span class="mt-1 {config.icon}"></span>

                  <div>
                    <p>{config.title}</p>
                    <span class="text-sm text-muted whitespace-nowrap">
                      {config.format_identifier(channel.identifier)}
                    </span>
                  </div>
                </button>
              </li>
            {/each}
          </ul>
        </div>
      </div>
    </Form>
  {/if}
</Modal>

<style lang="postcss">
  input[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='transparent' xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E%0A");

    background-repeat: no-repeat;
  }

  input[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='%23047857' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E%0A");
  }

  input[type="checkbox"]:disabled:checked {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='%239ca3af' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E%0A");
  }
</style>
