<script>
  /** @type {string[]} */
  export let items = []

  let class_name = ''
  export { class_name as class }
</script>

<ul class="space-y-3 leading-6 {class_name}">
  {#each items as item}
    <li class="flex gap-x-3">
      <span class="i-feather-check h-6 w-5 text-emerald-600"></span>

      { @html item }
    </li>
  {/each}
</ul>
