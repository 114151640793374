/**
 * @typedef {Object} Platform
 * @property {'x' | 'instagram'} key
 * @property {string} title
 * @property {string} icon
 * @property {string} base_url
 */

/** @type {Record<Platform['key'], Platform>} */
export const social_platforms = {
  x: {
    key: 'x',
    title: 'X',
    icon: 'i-hugeicons-new-twitter',
    base_url: 'https://x.com'
  },
  instagram: {
    key: 'instagram',
    title: 'Instagram',
    icon: 'i-hugeicons-instagram',
    base_url: 'https://instagram.com'
  }
}

export default social_platforms
