<script>
  import { tick } from 'svelte'
  import { SvelteURL } from 'svelte/reactivity'
  import { page, inertia } from '@inertiajs/svelte'

  import {
    Form,
    PhotoForm,
    SlideTransition,
    EventLayout,
    Transition
  } from '$lib/components'

  import EventTimeForm from './EventTimeForm.svelte'
  import LocationInput from './LocationInput.svelte'

  /** @type {{
    event: import('$types').Event
    submit_title: string
    action: string
    children: import('svelte').Snippet
  }} */
  let {
    event = $bindable({}),
    submit_title = '',
    action,
    children,
    ...restProps
  } = $props()

  let url = $state(new SvelteURL($page.props.url))

  let tab_value = $state(url.searchParams.get('tab') || 'details')

  const update_form_tab = () => {
    const searchParams = new URLSearchParams(window.location.search);

    tab_value = searchParams.get('tab') || 'details'
  }

  /** @param {InputEvent} event */
  const set_search_param = ({ target }) => {
    url.searchParams.set('tab', target?.value)

    window.history.replaceState(null, '', url.toString())
  }

  /** @type {HTMLTextAreaElement} */
  let description_el = $state()

  let show_description = $state(!!event.description)
  let show_visible_location = $state(!!event.visible_location)


  /** @type {{ id?: string, is_example?: boolean }} */
  let _event
  // export { _event as event }

  /**
   * Blob ID that's already been uploaded
   *
   * @type {Promise<string> | undefined}
   */
  let photo_blob_id = $state()

  const is_new_event = !_event

  const event_store_key = `${ is_new_event ? 'new' : _event.id }_event`

  const query = new URLSearchParams()

  const event_params = {
    title: query.get('title'),
    start_time: query.get('start_time'),
    end_time: query.get('end_time'),
    description: query.get('description'),
    location: query.get('location')
  }

  /** @type {import('$lib/types').Event} */
  // let event = is_new_event
  //   ? Object.assign({}, { visibility: 'hidden', guests_can_post: true, is_guest_list_visible: true }, $store[event_store_key] || {}, event_params || {})
  //   : _event

  // $: if (is_new_event) $store[event_store_key] = event

</script>

{#if !$page.props.current_user?.name}
  <div class="mb-6 shadow shadow-sky-900/10 bg-info dark:bg-info-content/50 text-info-content dark:text-info rounded px-4 py-2">
    <p>To { event.id ? 'create' : 'update' } an event, <a use:inertia class="border-b border-current hover:border-transparent transition-colors" href="/profile">set your name</a> lest people receive nameless invites!</p>
  </div>
{/if}

<svelte:window on:popstate={update_form_tab} />

<Form
  id="event_form"
  {action}
  method={event.id ? 'patch' : 'post'}
  onsubmit={async () => {
    await photo_blob_id
    await tick()
  }}
  bind_keyboard_submission
  {...restProps}
>
  <EventLayout
    {actions}
    {photo}
    {primary_details}
    {secondary_details}
  />
</Form>

{#snippet actions()}
  <div class="flex justify-between items-center">
    <div>
      {@render children?.()}
    </div>

    <button
      type="submit"
      class="btn btn-primary h-10 min-h-10"
      form="event_form"
    >
      {submit_title}

      <div class="flex gap-1 -mr-1.5">
        <kbd class="kbd kbd-sm kbd-primary">⌘</kbd>
        <kbd class="kbd kbd-sm kbd-primary">⏎</kbd>
      </div>
    </button>
  </div>
{/snippet}

{#snippet photo()}
  <div class="space-y-2">
    <div>
      <PhotoForm
        bind:photo_blob_id
        src={event.photo_url}
        raw_src={event.raw_photo_url}
        alt={event.title}
      />
    </div>

    {#if photo_blob_id}
      {#await photo_blob_id then value}
        {#if value}
          <input type="hidden" name="photo" value={value}>
        {/if}
      {/await}
    {/if}
  </div>
{/snippet}

{#snippet primary_details()}
  <label class="form-control">
    <div class="label pt-0">
      <span class="label-text text-base font-medium">Title</span>
    </div>
    <input
      type="text"
      required
      id="title"
      name="title"
      bind:value={event.title}
      class="input input-bordered w-full"
      placeholder="Time Traveller's Soirée"
    />
  </label>

  <div>
    <div class="label pt-0">
      <div>
        <label class="block label-text font-medium text-base" for="visibility">Visibility</label>

        <SlideTransition key={event.visibility}>
          <p class="text-sm text-muted">
            {#if event.visibility === 'private'}
              Only people you've invited can RSVP.
            {:else if event.visibility === 'hidden'}
              Anyone with the link can RSVP to this event.
            {/if}
          </p>
        </SlideTransition>
      </div>

      <div role="tablist" class="tabs tabs-boxed">
        <input
          type="radio"
          aria-label="Private"
          role="tab"
          class="tab !rounded-md"
          name="visibility"
          value="private"
          bind:group={event.visibility}
        />

        <input
          type="radio"
          aria-label="Hidden"
          role="tab"
          class="tab !rounded-md"
          name="visibility"
          value="hidden"
          bind:group={event.visibility}
        />
      </div>
    </div>
  </div>

  <EventTimeForm bind:event />

  <div>
    <label for="location" class="form-control">
      <div class="label pt-0">
        <span class="block label-text font-medium text-base">
          Location
        </span>

        <button
          type="button"
          class="btn btn-sm -my-1 {show_visible_location ? 'hidden' : 'block'}"
          onclick={() => show_visible_location = true}
        >Hide location</button>
      </div>

      <LocationInput bind:event />
    </label>
  </div>

  <Transition
    key={show_visible_location}
    from={{ duration: 150, delay: 150, y: -5 }}
    to={{ duration: 150, y: 5 }}
    on:outroend={() => description_el.focus()}
  >
    {#if show_visible_location}
      <label class="form-control">
        <div class="label pt-0">
          <span class="label-text font-medium text-base">Visible location</span>
        </div>

        <!-- errors={ $page.form?.errors?.visible_location } -->

        <input
          type="text"
          id="visible_location"
          name="visible_location"
          bind:value={event.visible_location}
          class="input input-bordered w-full"
          placeholder="Somewhere secret&hellip;"
        />

        <div class="label pb-0">
          <span class="label-text-alt text-sm text-muted">People who haven't RSVP'd will see this. Leave it blank to keep the location public.</span>
        </div>
      </label>
    {/if}
  </Transition>
{/snippet}

{#snippet secondary_details()}
  <Transition
    key={show_description}
    from={{ duration: 150, delay: 150, y: -5 }}
    to={{ duration: 150, y: 5 }}
    on:outroend={() => description_el.focus()}
  >
    {#if show_description}
      <div class="form-control">
        <div class="label pt-0 justify-start space-x-px">
          <label for="description" class="label-text font-medium text-base">
            Description
          </label>
        </div>

        <textarea
          bind:this={description_el}
          id="description"
          name="description"
          class="textarea textarea-bordered w-full text-base"
          rows={3}
          bind:value={event.description}
          placeholder="Join us for temporal canapés, interstitial drinks and a night of clock dancing."
        ></textarea>
      </div>
    {:else}
      <button type="button" class="btn w-full" onclick={() => show_description = true}>
        Add description
      </button>
    {/if}
  </Transition>

  <div>
    <div class="label pt-0">
      <div>
        <label class="block label-text font-medium text-base" for="attendance_limit">Attendance limit</label>

        <p class="text-sm text-muted">
          Leave it blank to allow limitless RSVPs.
        </p>
      </div>

      <input
        type="number"
        id="attendance_limit"
        name="attendance_limit"
        bind:value={event.attendance_limit}
        class="input input-bordered min-w-0 w-20"
        placeholder="42"
        min="0"
      />
    </div>
  </div>

  <div>
    <div class="label pt-0 items-start">
      <div>
        <label class="block label-text font-medium text-base" for="is_guest_list_visible">Show guest list</label>

        <SlideTransition key={event.is_guest_list_visible}>
          <p class="text-sm text-muted">
            {#if event.is_guest_list_visible}
              Show the guest count, and let RSVPs view the guest list.
            {:else}
              Hide the guest list and number of people going.
            {/if}
          </p>
        </SlideTransition>
      </div>

      <input type="hidden" name="is_guest_list_visible" value={false} />

      <input
        type="checkbox"
        id="is_guest_list_visible"
        name="is_guest_list_visible"
        class="toggle toggle-secondary mt-1"
        bind:checked={event.is_guest_list_visible}
        value={true}
      />
    </div>
  </div>

  <div>
    <div class="label items-start pt-0">
      <div>
        <label class="block label-text font-medium text-base" for="guests_can_invite">Guests can invite</label>

        <SlideTransition key={event.guests_can_invite}>
          <p class="text-sm text-muted">
            {#if event.guests_can_invite}
              Guests can invite people to the event.
            {:else}
              Only hosts can invite people to the event.
            {/if}
          </p>
        </SlideTransition>
      </div>

      <input type="hidden" name="guests_can_invite" value={false} />

      <input
        type="checkbox"
        id="guests_can_invite"
        name="guests_can_invite"
        class="toggle toggle-secondary mt-1"
        bind:checked={event.guests_can_invite}
        value={true}
      />
    </div>
  </div>

  <div>
    <div class="label items-start pt-0">
      <div>
        <label class="block label-text font-medium text-base" for="guests_can_post">Guests can post</label>

        <SlideTransition key={event.guests_can_post}>
          <p class="text-sm text-muted">
            {#if event.guests_can_post}
              Guests can post messages on the event page.
            {:else}
              Guests cannot post messages on the event page.
            {/if}
          </p>
        </SlideTransition>
      </div>

      <input type="hidden" name="guests_can_post" value={false} />

      <input
        type="checkbox"
        id="guests_can_post"
        name="guests_can_post"
        class="toggle toggle-secondary mt-1"
        bind:checked={event.guests_can_post}
        value={true}
      />
    </div>
  </div>
{/snippet}
