<script>
  import { page, inertia } from '@inertiajs/svelte'
  import { EventForm } from '$lib/components'

  /** @type {import('$types').Event} */
  let event = $state($page.props.event)
</script>

<svelte:head>
  <title>{ event.title } • Occasionly</title>
</svelte:head>

<EventForm
  action="/events/{event.id}"
  submit_title="Save event"
  bind:event
>
  <a use:inertia href="/events/{event.id}" class="text-gray-500">&larr; Back</a>
</EventForm>
