<script>
  /** @type {string} */
  export let text = ''

  /** @type {boolean} */
  export let renderLinks = true

  /**
   * @type {string}
   * @alias class
   */
  let class_name = ''
  export { class_name as class }

  /**
   * @param {string} link
   */
  const link = (link, text = link) => `<a href="${ (link.startsWith('http') ? '' : 'https://') + link }" class="text-sky-600 hover:underline break-all" target="_blank">${ text }</a>`

  /** @param {string} text */
  const format = (text) => {
    let result = text
      .replace(/(?:\*)(?:(?!\s))((?:(?!\n|\*).)+)(?:\*)/g,'<b>$1</b>')              // Bold
      .replace(/(?:\b_)(?:(?!\s))((?:(?!\n|_).)+)(?:_\b)/g,'<i>$1</i>')             // Italic
      .replace(/(?:~\b)(?:(?!\s))((?:(?!\n|~).)+)(?:\b~)/g,'<s>$1</s>')             // Strikethrough
      .replace(/(?:--\b)(?:(?!\s))((?:(?!\n|--).)+)(?:\b--)/g,'<u>$1</u>')          // Underline
      .replace(/(?:```\b)(?:(?!\s))((?:(?!\n|```).)+)(?:\b```)/g,'<code>$1</code>') // Monospace

    if (renderLinks) {
      return result
        .replace(
          /((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?([\/\w\.\-&\?=]*)?)\b/gi,
          (match) => link(match)
        )
        // .replace(/\[([^\[]+)\]\((.*)\)/gi, (match, text, href) => link(href, text))
    }

    return result
  }
</script>

<p class="{class_name}" {...$$restProps}>
  { @html format(text) }
</p>
