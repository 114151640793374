<script>
  import WhatsAppMarkdown from '$lib/components/WhatsAppMarkdown.svelte'

  /** @type {import('$lib/types').Entry<'Post'>} */
  export let entry

  $: post = entry.entryable
</script>

<WhatsAppMarkdown text={post.text} class="mt-1 whitespace-pre-line" />
