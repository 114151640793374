<script>
  import { social_platforms } from '$lib/config'

  /** @type {{ user: import('$lib/types').User, class: string }} */
  let { user, class: class_name = '' } = $props()
</script>

<div class="{class_name}">
  {#each Object.values(social_platforms) as platform}
    <div class="label py-0">
      <label class="block label-text font-medium text-base" for="socials_{platform.key}">{platform.title}</label>

      <div class="relative">
        <span class="{platform.icon} h-4 w-4 left-3 absolute top-1/2 -translate-y-1/2 text-base-content/70"></span>

        <input
          type="text"
          spellcheck="false"
          class="pl-9 input-sm input input-bordered"
          name="socials_{platform.key}"
          id="socials_{platform.key}"
          value={user.socials[platform.key] || null}
        />
      </div>
    </div>
  {/each}
</div>
