import { isPast } from 'date-fns'

/**
 * @typedef {object} AttendanceState
 * @property {import('$lib/types').Attendance['status']} key
 * @property {string} title
 * @property {string} color
 * @property {string} icon
 */

/**
 * @param {import('$lib/types').Event} [event]
 * @returns {Record<import('$lib/types').Attendance['status'], AttendanceState>}
 */
export const attendanceStates = (event) => {
  const isInPast = isPast(new Date(event?.end_time || new Date()))

  return {
    going: {
      key: 'going',
      title: isInPast ? 'Went' : 'Going',
      color: 'emerald',
      icon: 'i-feather-check'
    },
    maybe: {
      key: 'maybe',
      title: 'Maybe',
      color: 'amber',
      icon: 'i-ion-help-sharp'
    },
    payment_required: {
      key: 'payment_required',
      title: isInPast ? "Didn't buy a ticket" : 'Awaiting payment',
      color: 'amber',
      icon: 'i-feather-alert-circle'
    },
    cannot_go: {
      key: 'cannot_go',
      title: isInPast ? "Didn't go" : 'Not going',
      color: 'rose',
      icon: 'i-feather-x'
    },
    invited: {
      key: 'invited',
      title: 'Invited',
      color: 'sky',
      icon: 'i-feather-mail'
    }
  }
}

export { attendanceStates as attendance_states }

export default attendanceStates
