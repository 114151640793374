/**
 * Creates a debounced version of a function that delays its execution.
 *
 * @param {Function} mainFunction - The main function to debounce.
 * @param {number} delay - The delay in milliseconds for debouncing.
 * @returns {Function} A debounced function that delays execution of mainFunction.
 */
export const debounce = (mainFunction, delay) => {
  /** @type {number | undefined} */
  let timer

  // return an anonymous function that takes in any number of arguments
  return function (...args) {
    // clear the previous timer to prevent the execution of 'mainFunction'
    clearTimeout(timer)

    // set a new timer that will execute 'mainFunction' after the specified delay
    timer = setTimeout(() => {
      mainFunction(...args)
    }, delay)
  }
}
