<script>
  import { highlight } from '$lib/utilities'
  import { Avatar, NotificationStatus } from '$lib/components'
  import { page } from '@inertiajs/svelte'

  /** @type {{
    attendance: import('$types').Attendance,
    color: string,
    query: string,
    select_attendance: function(import('$types').Attendance):any
  }} */
  let { attendance, color, query, select_attendance } = $props()

  let { current_attendance } = $derived($page.props)
</script>

<li class="flex items-center justify-between relative">
  <!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
  <!-- svelte-ignore a11y_click_events_have_key_events -->
  <label
    for="attendee-page"
    class="contents group cursor-pointer"
    onclick={ () => select_attendance(attendance)}
  >
    <div class="flex relative">
      <Avatar user={ attendance.user } {color} class="w-6 h-6" />

      {#if attendance.is_host}
        <div class="bg-white shadow shadow-secondary/20 h-4 w-4 absolute top-[-7px] right-[-7px] flex items-center justify-center rounded-full">
          <span class="i-mdi-crown-outline text-secondary h-3.5 w-3.5"></span>
        </div>
      {/if}

      {#if attendance.reason}
        <div class="bg-white shadow shadow-accent/20 h-4 w-4 absolute bottom-[-7px] right-[-7px] flex items-center justify-center rounded-full">
          <span class="i-mdi-fountain-pen-tip text-accent h-3.5 w-3.5"></span>
        </div>
      {/if}
    </div>

      <p class="ml-1.5 truncate relative">
        <span class="group-hover:underline {attendance.is_host && 'text-secondary'}">
          {@html highlight(attendance.user?.name, query)}
        </span>
      </p>
  </label>

  <div class="flex items-center justify-end whitespace-nowrap space-x-1.5 ml-4 flex-1">
    {#if attendance.probability}
      <span class="text-gray-500">({ parseFloat(attendance.probability.toFixed(2)) }% likely)</span>
    {/if}

    {#if attendance.updated_by_user_id && attendance.updated_by_user_id !== attendance.user_id}
      <div class="tooltip tooltip-left ml-1" data-tip="Updated by the host">
        <div class="flex justify-center items-center">
          <span class="i-feather-flag h-4.5 w-4.5 text-amber-500"></span>
        </div>
      </div>
    {/if}

    {#if current_attendance?.is_host }
      <NotificationStatus statuses={ attendance.notification?.statuses || {} } />
    {/if}
  </div>
</li>