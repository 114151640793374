/**
 * @template T
 * @param {T[]} array
 * @param {function(T):boolean} conditionFn — the condition function on which to
 *   partition the array
 */
export const arrayPartition = (array, conditionFn) =>
  array.reduce(
    /**
     * @param {[T[], T[]]} arr
     * @param {T} item
     * @returns {[T[], T[]]}
     */
    ([a, b], item) => {
      conditionFn(item) ? a.push(item) : b.push(item)

      /** @type {T[]} */
      return [a, b]
    },
    [[], []]
  )

export { arrayPartition as array_partition }