<script>
  import { untrack } from 'svelte'
  import { page } from '@inertiajs/svelte'
  import { fly } from 'svelte/transition'
  import { flip } from 'svelte/animate'
  import { random_id } from '$lib/utilities'

  /** @type {any[]} */
  let notifications = $state([])

  /** @type {any[]} */
  let notifications_data = []
  export { notifications_data as notifications }

  /** @param {any} notification */
  const append = ([type, [title, body]]) => {
    notifications.push({ type, title, body, id: random_id() })
  }

  $effect(() => {
    $page.props.flash

    untrack(() => {
      Object.entries($page.props.flash).forEach(append)
    })
  })

  const MESSAGE_TIMEOUT_MS = 3000

  /** @type {import('svelte/action').Action}  */
  const remove_message = (node) => {
    setTimeout(() => {
      const index = notifications.findIndex(message => message.id === node.id)

      if (index !== -1) notifications.splice(index, 1)
    }, MESSAGE_TIMEOUT_MS)
  }
</script>

<div class="toast toast-top toast-end z-[1000] sm:max-w-sm w-full !p-0 top-3 right-3">
  {#each notifications as { type, title, body, id } (id) }
    <div {id} use:remove_message animate:flip out:fly={{ duration: 300, x: 50 }} role="alert" class="alert py-3 px-4 shadow-lg bg-base-100">
      {#if type === 'success'}
        <span class="i-heroicons-check-circle text-emerald-500 shrink-0 w-6 h-6"></span>
      {:else if type === 'info'}
        <span class="i-heroicons-exclamation-circle text-sky-500 shrink-0 w-6 h-6"></span>
      {:else if type === 'warning'}
        <span class="i-heroicons-exclamation-triangle text-amber-500 shrink-0 w-6 h-6"></span>
      {:else if type === 'error'}
        <span class="i-heroicons-exclamation-circle text-rose-500 shrink-0 w-6 h-6"></span>
      {/if}

      <div class="space-y-1">
        <p class="text-sm font-semibold">{@html title}</p>

        {#if body}
          <p class="text-sm">{@html body}</p>
        {/if}
      </div>
    </div>
  {/each}
</div>

