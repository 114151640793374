<script lang="ts">
  import { usePoll } from '@inertiajs/svelte'
  import {
    EventLayout,
    EventDetails,
    EventEntries,
    EventAttendance,
    EventHeader,
    EventActions,
    SavedEventChanges,
    WhatsAppMarkdown,
    GuestList,
    Avatar
  } from '$lib/components'

  /** @type {import('$lib/types').Event} */

  /** @type {import('$types').PageProps<'event' | 'current_user' | 'current_attendance'>} */
  let { event, current_user, current_attendance } = $props()

  const host_conjunction_for = (index) => {
    if (event.hosts.length === 1 || index == event.hosts.length - 1) return ''

    if (index === event.hosts.length - 2) return ' and '

    return ', '
  }

  usePoll(5000)
</script>

<svelte:head>
  <title>{event.title} • Occasionly</title>

  <meta name="title" property="og:title" content="{event.title}" />
  <meta name="type" property="og:type" content="website" />
  <meta name="image" property="og:image" content="{event.photo_url}&width=800" />
  <meta name="description" property="og:description" content="{event.description}" />

  <meta name="twitter:title" content="{event.title}" />
  <meta name="twitter:image" content="{event.photo_url}&width=800&aspect_ratio=1.91:1" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:site" content="@occasionly_io" />
</svelte:head>

<EventLayout
  {actions}
  {photo}
  {primary_details}
  {secondary_details}
  {updates}
/>

{#snippet actions()}
  <EventActions {event} {current_attendance} />
{/snippet}

{#snippet photo()}
  <EventHeader {event} {current_attendance} />

  <p class="max-md:hidden leading-relaxed">
    <span>Hosted by</span> {#each event.hosts as user, index}{@render host(user, index)}{/each}.
  </p>
{/snippet}

{#snippet host(user, index)}
  <Avatar {user} class="w-6 h-6 -mb-[6px]" />
  <strong>{user.name}</strong>{host_conjunction_for(index)}
{/snippet}

{#snippet primary_details()}
  <div class="max-md:hidden">
    <h1 class="text-4xl font-bold">{event.title}</h1>
  </div>

  <div class="md:order-last">
    <EventAttendance {event} {current_attendance} />
  </div>

  <div class="space-y-3">
    <EventDetails {event} {current_attendance} />
  </div>
{/snippet}

{#snippet secondary_details()}
  <GuestList />

  {#if event.description}
    <div>
      <div class="flex justify-between items-center">
        <h3 class="text-lg font-semibold">About</h3>
      </div>

      <div>
        <WhatsAppMarkdown text={event.description} class="break-words whitespace-pre-line" />
      </div>
    </div>
  {/if}
{/snippet}

{#snippet updates()}
  <EventEntries {event} />
{/snippet}

<!-- {#if $page.url.searchParams.has('changes')}
  <SavedEventChanges />
{/if} -->
