<script>
  import { copy_to_clipboard } from '$lib/utilities'
  import { ButtonNotifier } from '$lib/components'

  /** @type {string} */
  export let link

  let class_name = ''
  export { class_name as class }
</script>

<ButtonNotifier>
  {#snippet children(notify)}
    <div class="join {class_name} w-full">
      <input
        class="select-all input input-bordered w-full join-item cursor-pointer"
        value={link}
        readonly
        on:click={async () => await copy_to_clipboard(link) && notify('Copied!')}
      />

      <button
        aria-label="Copy to clipboard"
        type="button"
        class="btn join-item"
        on:click={async () => await copy_to_clipboard(link) && notify('Copied!')}>
      <span class="i-feather-copy h-5 w-5"></span>
    </button>
    </div>
  {/snippet}
</ButtonNotifier>