/** @param {string} text */
export const copy_to_clipboard = async (text) => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text)
  } else {
    copy_to_clipboard_fallback(text)
  }

  return true
}

/** @param {string} text */
const copy_to_clipboard_fallback = (text) => {
  const input = document.createElement('textarea')
  input.innerHTML = text
  document.body.appendChild(input)
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
}
