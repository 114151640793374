<script>
  import { onMount } from 'svelte'
  import { inertia } from '@inertiajs/svelte'

  import { Modal, Form, TimeZonePicker } from '$lib/components'
  import { store, time_zone, current_time_zone } from '$lib/stores'

  /** @type {Modal} */
  let modal = $state()

  /** @type {{ user: import('$lib/types').User }} */
  let { user } = $props()

  onMount(() => modal.show())
</script>

<Modal
  bind:this={modal}
  class="overflow-visible"
  onclose={() => $store.ignored_time_zone = current_time_zone}
>
  <h3 class="text-2xl font-semibold">You're in a different time zone</h3>

  <p class="mt-3">It looks like you're in <span class="font-semibold">{current_time_zone.replace(/_/g, ' ')}</span>, but your account is set to <span class="font-semibold">{user.time_zone.replace(/_/g, ' ')}</span>. Click 'Save' below to change it, or cancel.</p>

  <p class="mt-3">You can always change it later on <a use:inertia href="/profile" class="underline" onclick={() => modal.hide()}>your profile</a>.</p>

  <Form
    action="/users/{user.id}"
    class="mt-3"
    method="patch"
    onsuccess={ (page) => {
      $time_zone = page.props.current_user.time_zone

      modal.hide()
    }}
  >
    <TimeZonePicker value={current_time_zone} />

    <button type="submit" class="mt-6 w-full btn btn-primary">
      Save
    </button>
  </Form>
</Modal>
