<script>
  import { page, inertia } from '@inertiajs/svelte'
  import { EventForm } from '$lib/components'

  /** @type {import('$types').Event} */
  let event = $state($page.props.event)
</script>

<svelte:head>
  <title>New event • Occasionly</title>
</svelte:head>

<EventForm bind:event action="/events" submit_title="Create event">
  <a use:inertia href="/events" class="text-gray-500">&larr; Events</a>
</EventForm>
