const COLORS = [
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'indigo',
  'violet',
  'purple',
  'pink'
]

/**
 * @param {string} name
 */
export const generateColor = (name) => {
  const sum = name
    .split('')
    .reduce((memo, char) => memo + char.charCodeAt(0), 0)

  return COLORS[sum % COLORS.length]
}
