<script>
  import { inertia, page } from '@inertiajs/svelte'

  let { current_user } = $props()

  let [first_name] = $derived((current_user.name || '').split(' '))

  let intro = $derived(`${ first_name ? `Hey ${first_name}, it's ` : "It's "}wonderful having you as a customer.`)

  let href = $derived(new URL($page.props.url).searchParams.get('return_url') || '/events')
</script>

<svelte:head>
  <title>Thank you! • Occasionly</title>
</svelte:head>

<img src="/thank-you-party.webp" class="w-full aspect-video object-cover rounded-box" alt="Thank you!" />

# Thank you!

{intro}

I started Occasionly to make it the simplest place for anyone to host events with their people, outside of the walled gardens of big tech companies. We're only getting started, and I'm thrilled you're now on the journey.

We don't serve ads, we don't mine your data, we never sell anything you give us, and we protect your privacy.

If you ever need a hand, contact me directly at nick@occasionly.io or on X [@nbgoodall](https://x.com/nbgoodall).

Thanks again and all the best,

<img src="/nick-signature.webp" alt="Thank you" class="h-10">

Nick Goodall, creator of Occasionly

<div class="not-prose">
  <a use:inertia {href} class="btn btn-primary">&larr; Head back</a>
</div>
