<script>
  import { untrack, setContext } from 'svelte'
  import { random_id } from '$lib/utilities'
  import { add_escape_listener, remove_escape_listener } from '$lib/stores'

  /** @type {{
    children: import('svelte').Snippet
    id?: string
    open?: boolean
    class?: string
    onopen?: function():any
    onclose?: function():any
  }} */
  let {
    children,
    id = random_id(),
    open = $bindable(false),
    class: class_name = '',
    onclose = undefined,
    onopen = undefined
  } = $props()

  setContext('container', {
    get open() { return open }
  })

  let effected = false
  $effect(() => {
    open // dependency tracking

    untrack(() => {
      if (!effected) return effected = true

      if (open) {
        add_escape_listener(id, hide)
      }
    })

    return () => {
      remove_escape_listener(id)
    }
  })

  export const show = () => open = true
  export const hide = () => open = false
</script>

<input type="checkbox" {id} class="modal-toggle" bind:checked={open} />

<div
  class="modal invisible max-sm:modal-bottom"
  role="dialog"
  ontransitionend={(evt) => {
    if (evt.propertyName === 'visibility') {
      if (open) {
        onopen && onopen()
      } else {
        onclose && onclose()
      }
    }
  }}
>
  <div class="modal-box {class_name}">
    <label for={id} class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" tabindex="-1">
      <span class="i-feather-x w-5 h-5"></span>
    </label>

    <div>
      {@render children()}
    </div>
  </div>

  <label class="modal-backdrop" for={id} tabindex="-1"><span class="sr-only">Close</span></label>
</div>
