<script>
  import { page } from '@inertiajs/svelte'

  import { Pages } from '$lib/components'
  import CalendarFeeds from './CalendarFeeds.svelte'
  import SubscribeToGcal from './SubscribeToGcal.svelte'

  /** @type {{ event: import('$types').Event, current_attendance: import('$types').Attendance }} */
  let { event, current_user } = $page.props

  let rest_props = $props()
</script>

<Pages.Page id="add-to-calendar-page" {...rest_props}>
  <h3 class="text-2xl">
    Add <strong>{ event.title }</strong> to your calendar
  </h3>

  <a
    href="{new URL($page.props.url).origin}/events/{event.id}.ics{$page.props.token ? `?auth_token=${ $page.props.token }` : ''}"
    class="btn btn-primary w-full text-base mt-3"
  >Add to Calendar</a>

  <div class="@container">
    {#if current_user}
      <div class="divider text-neutral/50">Or</div>

      <p class="mt-3 font-normal @sm:text-center">
        Subscribe to your automatically-updated feed of events:
      </p>

      <CalendarFeeds calendar={current_user.calendar} />
    {:else}
      <p class="mt-2 py-3 text-center text-gray-500">
        <em
          ><label
            for="login-modal"
            class="cursor-pointer text-emerald-600 underline hover:no-underline"
            >Sign in</label
          >
          to subscribe to a feed of your events.</em
        >
      </p>
    {/if}
  </div>
</Pages.Page>

{#if current_user}
  <Pages.Page id="subscribe-to-gcal" {...rest_props}>
    <SubscribeToGcal calendar={current_user.calendar} />
  </Pages.Page>
{/if}