<script>
  import { page } from '@inertiajs/svelte'
  import { Errors, SlideTransition } from '$lib/components'

  const { event } = $derived($page.props)

  const MAX_TITLE_LENGTH = 100

  /** @param {string} title */
  const get_title = (title) => event.title.length > MAX_TITLE_LENGTH ? title.split(' ').map(word => word[0].toUpperCase()).join('') : event.title

  /** @type {import('$lib/types').GroupChat} */
  let empty_group_chat = {
    id: '',
    is_read_only: false,
    title: get_title(event.title),
    whatsapp_id: '',
    whatsapp_invite_code: ''
  }

  let {
    groupChat = empty_group_chat,
    is_read_only = empty_group_chat.is_read_only,
    title = $bindable(empty_group_chat.title)
  } = $props()

  /** @type {boolean} */
  let is_title_dirty

  let default_title = $derived(get_title(event.title).slice(0, MAX_TITLE_LENGTH))

  let is_new = $derived(!groupChat?.id)
  $effect(() => {
    if (is_new && !is_title_dirty) title = default_title
  })
</script>

<label class="form-control w-full">
  <div class="label pb-0.5">
    <span class="label-text font-medium text-base">Title</span>
  </div>

    <!-- bind:dirty={is_title_dirty} -->
  <div class="relative">
    <input
      class="input input-bordered w-full"
      required
      name="entryable_attributes[title]"
      id="title"
      maxlength={ MAX_TITLE_LENGTH }
      bind:value={title}
      placeholder={default_title}
    />

    <div class="absolute end-3 top-1/2 -translate-y-1/2 pointer-events-none">
      <span class="text-muted">(max {MAX_TITLE_LENGTH})</span>
    </div>
  </div>

  <Errors errors={$page.form?.errors?.title} />
</label>

<div class="label mt-3">
  <label class="block label-text font-medium text-base" for="is_read_only">Read-only</label>

  <input type="hidden" name="entryable_attributes[is_read_only]" value={false} />

  <input
    type="checkbox"
    id="is_read_only"
    name="entryable_attributes[is_read_only]"
    class="toggle toggle-secondary"
    bind:checked={is_read_only}
    value={true}
  />
</div>

<div class="label pt-0 -mt-1">
  <SlideTransition key={is_read_only}>
    <p class="text-sm text-muted">
      {#if is_read_only}
        Only hosts can send messages.
      {:else}
        Everyone can send messages.
      {/if}
    </p>
  </SlideTransition>
</div>
