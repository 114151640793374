<script>
  import { fly } from 'svelte/transition'
  import { DatePicker } from 'bits-ui'
  import { formatInTimeZone } from 'date-fns-tz'
  import { parseDate } from '@internationalized/date'

  /** @type {string | undefined} */
  export let value

  let class_name = ''
  export { class_name as class }

  /** @type {string | undefined} */
  export let min = undefined

  /** @type {string | undefined} */
  export let max = undefined
</script>

<DatePicker.Root
  weekdayFormat="short"
  fixedWeeks={false}
  weekStartsOn={1}
  numberOfMonths={1}
  onValueChange={date => {
    value = date?.toString()
  }}
  value={value ? parseDate(value) : undefined}
  minValue={min ? parseDate(min) : undefined}
  maxValue={max ? parseDate(max) : undefined}
>
  <div class="w-full flex flex-col">
    <DatePicker.Trigger>
      <DatePicker.Input
        class="relative input input-bordered flex items-center justify-between {class_name}"
      >
        <slot />

        <span class="{ value ? '' : 'text-muted' }">
          {formatInTimeZone(new Date(value || new Date()), 'GMT', 'eee d MMM')}
        </span>
      </DatePicker.Input>
    </DatePicker.Trigger>

    <DatePicker.Content
      sideOffset={6}
      transition={fly}
      transitionConfig={{ duration: 150, y: -8 }}
      class="z-50"
    >
      <DatePicker.Calendar
        class="dropdown-content z-[1] menu p-3 shadow bg-base-100 rounded-box w-full"
        let:months
        let:weekdays
      >
        <DatePicker.Header class="flex items-center justify-between">
          <DatePicker.PrevButton
            class="btn btn-square btn-ghost data-[disabled]:bg-base-100"
          >
            <span class="i-feather-chevron-left size-5"></span>
          </DatePicker.PrevButton>
          <DatePicker.Heading class="text-[15px] font-medium" />
          <DatePicker.NextButton
            class="btn btn-square btn-ghost"
          >
            <span class="i-feather-chevron-right size-5"></span>
          </DatePicker.NextButton>
        </DatePicker.Header>

        <div
          class="flex flex-col space-y-4 pt-4 sm:flex-row sm:space-x-4 sm:space-y-0"
        >
          {#each months as month}
            <DatePicker.Grid
              class="w-full border-collapse select-none space-y-1"
            >
              <DatePicker.GridHead>
                <DatePicker.GridRow class="mb-1 flex w-full justify-between">
                  {#each weekdays as day}
                    <DatePicker.HeadCell
                      class="w-10 text-xs font-normal text-base-content/70"
                    >
                      <div>{day.slice(0, 2)}</div>
                    </DatePicker.HeadCell>
                  {/each}
                </DatePicker.GridRow>
              </DatePicker.GridHead>
              <DatePicker.GridBody>
                {#each month.weeks as week_dates}
                  <DatePicker.GridRow class="flex w-full">
                    {#each week_dates as date}
                      <DatePicker.Cell
                        {date}
                        class="relative size-10 !p-0 text-center text-sm"
                      >
                        <DatePicker.Day
                          {date}
                          month={month.value}
                          class="group relative size-10 inline-flex items-center justify-center whitespace-nowrap rounded-btn border font-normal border-transparent hover:border-neutral focus:outline-none transition-[background-color,font-weight]
                          data-[selected]:bg-primary data-[selected]:text-primary-content data-[selected]:font-semibold
                          data-[disabled]:pointer-events-none data-[disabled]:text-base-content/40
                          "
                        >
                          <div
                            class="absolute top-[5px] hidden size-1 rounded-full bg-foreground transition-all group-data-[today]:block group-data-[selected]:bg-background"
                          />
                          {date.day}
                        </DatePicker.Day>
                      </DatePicker.Cell>
                    {/each}
                  </DatePicker.GridRow>
                {/each}
              </DatePicker.GridBody>
            </DatePicker.Grid>
          {/each}
        </div>
      </DatePicker.Calendar>
    </DatePicker.Content>
  </div>
</DatePicker.Root>