import { parsePhoneNumber } from 'awesome-phonenumber'

/**
 * @typedef {Object} ChannelConfig
 * @property {string} name
 * @property {string} title
 * @property {string} stylized
 * @property {string} icon
 * @property {function(string):string} format_identifier
 */

/** @type {Record<import('$lib/types').NotificationChannel['name'], ChannelConfig>} */
export const notification_channels = {
  whatsapp: {
    name: 'whatsapp',
    title: 'WhatsApp',
    stylized: 'WhatsApp',
    icon: 'i-ion-logo-whatsapp',

    /** @param {string} identifier */
    format_identifier: (identifier) => String(parsePhoneNumber('+' + identifier).number?.national)
  },

  email: {
    name: 'email',
    title: 'Email',
    stylized: 'email',
    icon: 'i-ion-mail-outline',

    /** @param {string} identifier */
    format_identifier: (identifier) => identifier
  },

  signal: {
    name: 'signal',
    title: 'Signal',
    stylized: 'Signal',
    icon: 'i-occasionly-signal-outline',

    /** @param {string} identifier */
    format_identifier: (identifier) => String(parsePhoneNumber('+' + identifier).number?.national)
  },

  telegram: {
    name: 'telegram',
    title: 'Telegram',
    stylized: 'Telegram',
    icon: 'i-uit-telegram-alt',

    /** @param {string} identifier */
    format_identifier: (identifier) => String(parsePhoneNumber('+' + identifier).number?.national)
  }
}

export default notification_channels
