<script>
  import { inertia, page } from '@inertiajs/svelte'
  import { scale } from 'svelte/transition';
  import { Avatar, Form, ChangelogModal } from '$lib/components'
  import LoginModal from './LoginModal.svelte'

  /** @type {{ user: import('$lib/types').User | undefined }} */
  let { user } = $props()

  let unread_count = $state(0)

  let is_app = $derived(!['static', 'prose'].some(layout => $page.component.startsWith(layout)))
</script>

<header>
  <nav class="flex items-center justify-between p-4 sm:p-6 lg:px-8" aria-label="Global">
    <div class="flex lg:flex-1">
      <a use:inertia href={is_app && user ? '/events' : '/'} class="-m-1.5 flex items-center p-1.5">
        <span class="sr-only">Occasionly</span>
        <img class="h-8 w-auto" src="/logo/occasionly_logo.production.png" alt="" />
        <h2 class="ml-2 text-base font-semibold max-sm:hidden">Occasionly</h2>
      </a>
    </div>

    <div class="hidden">
      <button
        type="button"
        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
    </div>

    <div class="flex -space-x-1 lg:space-x-4">
      {#if is_app}
        {#if user}
          <a use:inertia href="/events" class="text-base btn btn-ghost btn-sm font-normal">Events</a>
        {/if}

        <label
          for="help-modal"
          class="text-base btn btn-ghost btn-sm font-normal"
        >
          Help
        </label>
      {:else}
        <a use:inertia href="/updates" class="text-base btn btn-ghost btn-sm font-normal">Updates</a>
      {/if}
    </div>

    <div class="flex lg:flex-1 items-center leading-none lg:justify-end gap-2">
      {#if user}
        {#if is_app}
          <!-- <label
            for="help-modal"
            class="text-base btn btn-ghost btn-sm"
          >
            Help
          </label> -->

          {@render header_menu()}
        {:else}
          <a use:inertia href="/events" class="text-base btn btn-ghost btn-sm">
            Your events <span aria-hidden="true">&rarr;</span>
          </a>
        {/if}
      {:else}
        <label
          for="login-modal"
          class="text-base btn btn-sm {is_app ? 'btn-outline' : 'btn-ghost'}"
          >Log in {#if !is_app}<span aria-hidden="true">&rarr;</span>{/if}</label
        >
      {/if}
    </div>
  </nav>
</header>

{#snippet header_menu()}
  <div class="relative dropdown dropdown-end dropdown-hover -my-1">
    <div tabindex="0" role="button" class="btn btn-ghost btn-circle btn-sm !h-[2.2rem] !w-[2.2rem]">
      <Avatar {user} color="emerald" class="text-emerald-600 shadow w-full h-full transition-opacity group-hover:opacity-75" textClass="!text-sm" />
    </div>

    <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
    <div tabindex="0" class="dropdown-content pt-2 z-[1]">
      <ul tabindex="0" class="p-2 shadow menu bg-base-100 rounded-box w-52 border border-base-200">
        <li>
          <a use:inertia href="/profile" class="items-start">
            <span class="i-feather-user h-5 w-5"></span>

            <div class="flex flex-col text-left items-start">
              <span class="font-light">Signed in as</span>
              <span class="font-semibold truncate w-28">{ user.name }</span>
            </div>
          </a>
        </li>

        <li>
          <a use:inertia href="/calendars">
            <span class="i-feather-calendar h-5 w-5"></span>
            Calendar
          </a>
        </li>

        <li>
          <label for="changelog-modal" class="relative">
            <span class="i-feather-triangle h-5 w-5 flex-shrink-0"></span>

            {#if unread_count}
              <div transition:scale class="badge bg-error p-0 h-4 w-4 absolute top-0 left-[26px]">
                <span class="text-error-content text-xs leading-none">{unread_count}</span>
              </div>
            {/if}

            What's new
          </label>
        </li>

        <Form action="/auth" method="delete">
          <li class="text-rose-500">
            <button type="submit">
              <span class="i-feather-log-out h-5 w-5"></span>
              Sign out
            </button>
          </li>
        </Form>
      </ul>
    </div>

    {#if unread_count}
      <div transition:scale class="badge bg-error p-0 h-4 w-4 absolute top-[-3px] right-[-3px]">
        <span class="text-error-content text-xs leading-none">{unread_count}</span>
      </div>
    {/if}
  </div>

  <ChangelogModal bind:unread_count />
{/snippet}

{#if !user}
  <div class="relative z-20">
    <LoginModal />
  </div>
{/if}
