<script>
  import { parsePhoneNumber } from 'awesome-phonenumber'
  import { page } from '@inertiajs/svelte'
  import { notification_channels } from '$lib/config'

  import { Form, Errors, Modal, SlideTransition } from '$lib/components'

  // /** @type {{ user: import('$lib/types').User, modal_snipp }} */
  let { user, modals = $bindable() } = $props()

  modals = modal_snippets

  let whatsapp_number = $state(''), email = $state(''), signal_number = $state(''), telegram_number = $state('')

  /** @type {Modal} */
  let whatsapp_modal = $state()

  /** @type {Modal} */
  let email_modal = $state()

  /** @type {Modal} */
  let signal_modal = $state()

  /** @type {Modal} */
  let telegram_modal = $state()

  const AVAILABLE_CHANNELS = [
    {
      ...notification_channels.whatsapp,
      /**
       * @param {import('$lib/types').NotificationChannel} channel
       */
      description: (channel) => channel.activated_at
      ? `${ channel.is_active ? "We'll" : "We will not"} send WhatsApp notifications to <strong>${ parsePhoneNumber(channel.identifier).number.national }</strong>.`
      : `We've sent an activation message to <strong>${ parsePhoneNumber(channel.identifier).number.national }</strong>.`
    },
    {
      ...notification_channels.email,
      /**
       * @param {import('$lib/types').NotificationChannel} channel
       */
      description: (channel) => channel.activated_at
      ? `${ channel.is_active ? "We'll" : "We will not"} email notifications to <strong>${ channel.identifier }</strong>.`
      : `We've sent an activation message to <strong>${ channel.identifier }</strong>.`
    },
    {
      ...notification_channels.signal,
      /**
       * @param {import('$lib/types').NotificationChannel} channel
       */
      description: (channel) => channel.activated_at
      ? `${ channel.is_active ? "We'll" : "We will not"} send Signal notifications to <strong>${ parsePhoneNumber(channel.identifier).number.national }</strong>.`
      : `We've sent an activation message to <strong>${ parsePhoneNumber(channel.identifier).number.national }</strong>.`
    },
    {
      ...notification_channels.telegram,
      /**
       * @param {import('$lib/types').NotificationChannel} channel
       */
      description: (channel) => channel.activated_at
      ? `${ channel.is_active ? "We'll" : "We will not"} send Telegram notifications to <strong>${ channel.identifier }</strong> (Telegram User ID).`
      : `We've sent an activation message to <strong>${ channel.identifier }</strong> (Telegram User ID).`
    }
  ]
</script>

<div class="mt-8">
  <h3 class="text-2xl font-medium">Notifications</h3>

  <p class="mt-1">Select where you'd like to receive event invites and notifications.</p>

  <div class="mt-3">
    {#each AVAILABLE_CHANNELS as channel}
      {@const user_channel = user.notification_channels.find(({ name }) => name === channel.name)}

      <div class="label">
        <label class="block label-text font-medium text-base" for="{channel.name}-channel">{channel.title}</label>

        {#if user_channel}
          <input type="hidden" name="notification_channels_attributes[]id" value={ user_channel.id } />
          <input type="hidden" name="notification_channels_attributes[]is_active" value={ false } />

          <input type="hidden" name="notification_channels_attributes[]id" value={ user_channel.id } />

          <input
            type="checkbox"
            id="{channel.name}-channel"
            name="notification_channels_attributes[]is_active"
            class="toggle toggle-secondary"
            checked={!!user_channel.activated_at && user_channel.is_active}
            disabled={!user_channel.activated_at}
            value={true}
          />
        {:else}
          <label
            for="register-{ channel.name }-modal"
            class="btn btn-outline btn-sm"
          >
            <span class="{channel.icon} i-articons-signal h-4 w-4"></span>

            Add { channel.title }
          </label>
        {/if}
      </div>

      {#if user_channel}
        <div class="label py-0 -mt-1">
          <SlideTransition key={user_channel.is_active}>
            <p class="text-sm text-base-content/70">
               {@html channel.description(user_channel) }
            </p>
          </SlideTransition>
        </div>
      {/if}
    {/each}
  </div>

  <Errors errors={$page.form?.errors?.notification_channels} />
</div>

{#snippet modal_snippets()}
  <Modal id="register-whatsapp-modal" bind:this={whatsapp_modal}>
    <h3 class="text-2xl font-medium">Register with WhatsApp</h3>

    <p class="mt-3">Enter your phone number below to register with WhatsApp for notifications.</p>

    <Form
      class="mt-3"
      action="/notification_channels"
      onsuccess={() => whatsapp_modal.hide()}
    >
      <input type="hidden" name="type" value="WhatsAppChannel" />

      <input
        type="tel"
        class="input input-bordered w-full"
        placeholder="07700 900000"
        name="identifier"
        bind:value={whatsapp_number}
        required
      />
        <!-- errors={$page.form?.errors?.identifier} -->

      <button
        disabled={!whatsapp_number.length}
        type="submit"
        class="btn btn-primary text-base w-full mt-3"
      >Add WhatsApp</button>
    </Form>
  </Modal>

  <Modal id="register-email-modal" bind:this={email_modal}>
    <h3 class="text-2xl font-medium">Register your email</h3>

    <p class="mt-3">Enter your email below to register for notifications.</p>

    <Form
      class="mt-3"
      action="/notification_channels?/create"
      onsuccess={() => email_modal.hide()}
    >
      <input type="hidden" name="type" value="EmailChannel" />

      <input
        type="email"
        class="input input-bordered w-full"
        placeholder="you@example.com"
        name="identifier"
        bind:value={email}
        required
      />

      <Errors errors={$page.props.errors?.identifier} />
        <!-- errors={$page.form?.errors?.identifier} -->

      <button
        disabled={!email.length}
        type="submit"
        class="btn btn-primary text-base w-full mt-3"
      >Add email</button>
    </Form>
  </Modal>

  <Modal id="register-signal-modal" bind:this={signal_modal}>
    <h3 class="text-2xl font-medium">Register with Signal</h3>

    <p class="mt-3">Enter your phone number below to register with Signal for notifications.</p>

    <Form
      class="mt-3"
      action="/notification_channels?/create"
      onsuccess={() => signal_modal.hide()}
    >
      <input type="hidden" name="type" value="SignalChannel" />

      <input
        type="tel"
        class="input input-bordered w-full"
        placeholder="07700 900000"
        name="identifier"
        bind:value={signal_number}
        required
      />
        <!-- errors={$page.form?.errors?.identifier} -->

      <button
        disabled={!signal_number.length}
        type="submit"
        class="btn btn-primary text-base w-full mt-3"
      >Add Signal</button>
    </Form>
  </Modal>

  <Modal id="register-telegram-modal" bind:this={telegram_modal}>
    <h3 class="text-2xl font-medium">Register with Telegram</h3>

    <p class="mt-3">Enter your phone number below to register with Telegram for notifications.</p>

    <Form
      class="mt-3"
      action="/notification_channels?/create"
      onsuccess={() => telegram_modal.hide()}
    >
      <input type="hidden" name="type" value="TelegramChannel" />

      <input
        type="tel"
        class="input input-bordered w-full"
        placeholder="07700 900000"
        name="identifier"
        bind:value={telegram_number}
        required
      />
        <!-- errors={$page.form?.errors?.identifier} -->

      <button
        disabled={!telegram_number.length}
        type="submit"
        class="btn btn-primary text-base w-full mt-3"
      >Add Telegram</button>
    </Form>
  </Modal>
{/snippet}
