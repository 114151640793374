<script>
  let is_dragging = false

   /** @param {DragEvent} evt */
  const prevent_default_drop = (evt) => {
    /** @ts-ignore */
    if (evt.target?.type !== 'file') {
      evt.preventDefault()
    }
  }

  /** @param {DragEvent} evt */
  const handle_drop = ({ dataTransfer }) => {
    let file = dataTransfer?.files[0]

    if (file?.type === 'text/calendar') {
      let reader = new FileReader()

      reader.onload = async function (evt) {
        console.log('loaded!', evt.target.result)

        // render()
      }

      reader.readAsText(file);
    }
  }
</script>

<!--
<svelte:window
  on:dragover={ (evt) => {
    prevent_default_drop(evt)

    is_dragging = true
  }}
  on:dragleave={ () => is_dragging = false }
  on:dragenter={prevent_default_drop}
  on:drop={ (evt) => {
    prevent_default_drop(evt)

    is_dragging = false

    handle_drop(evt)
    // is_dragging_over = false
  }}
/>
 -->
{#if is_dragging}
  <div class="fixed flex items-center justify-center inset-0 pointer-events-none bg-primary/10 border-4 border-primary/50 backdrop-blur-sm z-[100]">
    <div class="bg-base-100 card card-bordered">
      <div class="card-body space-y-3">
        <h2 class="text-success text-center font-bold text-2xl leading-none">Maximum effort</h2>

        <p class="text-lg">Drop a <code>.ics</code> file and we'll turn it into an event for you.</p>
      </div>
    </div>
  </div>
{/if}