<script>
  import { page, inertia } from '@inertiajs/svelte'
  import { parseJSON, isPast } from 'date-fns'

  import WhatsAppMarkdown from '../WhatsAppMarkdown.svelte'
  import { attendance_label, format_datetime } from '$lib/utilities'
  import { time_zone } from '$lib/stores'

  /** @type {{ event: import('$lib/types').Event }} */
  let { event } = $props()

  let current_attendance = $derived((event.attendances || []).find(({ user_id }) => user_id === $page.props.current_user?.id))

  const [month, day] = format_datetime(parseJSON(event.start_time), 'MMM d', $time_zone).split(' ')

  let can_see_location = $derived(!event.visible_location || current_attendance?.status === 'going' || current_attendance?.is_host)

  const ATTENDANCE_COLORS = {
    invited: 'sky',
    going: 'emerald',
    cannot_go: 'rose',
    payment_required: 'sky',
    maybe: 'amber'
  }
</script>

<li class="list-none py-3">
  <a use:inertia href="/events/{event.id}" class="group block">
    <div class="flex md:h-32 gap-4 max-md:flex-col">
      <div>
        <div
          class="aspect-[16/9] relative h-full bg-gradient-to-br from-slate-300 via-stone-300 to-slate-500 bg-cover bg-center rounded-box"
        >
          {#if event.photo_url}
            <img
              class="h-full w-full object-cover rounded-box {event.id && 'shadow-xl hover:shadow-2xl transition-shadow' }"
              src="{ event.photo_url }&width=800"
              alt="{ event.title }"
            />
          {/if}

          {#if event.visibility === 'private'}
            <div class="absolute top-0 z-[1] p-3">
              <p class="rounded-btn bg-white/75 px-1.5 py-1 text-xs uppercase leading-none shadow backdrop-blur">
                Private
              </p>
            </div>
          {/if}

          <div
            class="backdrop-mask absolute bottom-0 flex max-h-full w-full items-end bg-gradient-to-b from-transparent via-black/60 to-black/90 backdrop-blur-[1px] rounded-box"
          >
            <div class="flex w-full justify-between items-end p-2 text-white space-x-2">
              <div class="flex items-center gap-1 w-full">
                <span class="h-5 w-5 i-mdi-map-marker-outline"></span>

                <p class="break-words truncate font-medium">
                  {#if can_see_location}
                    {event.location.formatted_address}
                  {:else}
                    {event.visible_location}
                  {/if}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full justify-between space-x-3">
        <div class="flex min-w-0 flex-col items-start justify-between gap-2">
          <div class="grid">
            <h3 class="-mt-1 text-xl font-medium transition-colors line-clamp-1">
              { event.title }
            </h3>

            {#if event.description}
              <WhatsAppMarkdown
                text={ event.description.slice(0, 250).replace(/\s+/g, ' ') }
                class="text-base text-muted line-clamp-2"
                renderLinks={false}
              />
            {/if}

          </div>

          {#if current_attendance}
            <div class="flex items-center space-x-2 whitespace-nowrap">
              <div class="detail-icon h-8 w-8">
                <span class="i-feather-flag text-{ ATTENDANCE_COLORS[current_attendance.status] }-400 h-6 w-6"></span>
              </div>

              <span>
                { attendance_label(current_attendance, { is_past: isPast(new Date(event.end_time)) }) }
              </span>
            </div>
          {/if}
        </div>

        <div class="detail-icon h-11 w-11">
          <div class="flex flex-col space-y-1">
            <span class="text-xs leading-none uppercase text-success">{month}</span>
            <span class="text-base leading-none">{day}</span>
          </div>
        </div>
      </div>
    </div>
  </a>
</li>

<style lang="postcss">
  .detail-icon {
    @apply flex-shrink-0 flex items-center justify-center shadow shadow-secondary/25 border rounded-btn text-center font-semibold group-hover:shadow-md transition-shadow;
  }

  .detail-icon > [class*='i-'] {
    heigh: 64%;
    width: 64%;
  }

  @supports (mask: linear-gradient(transparent, black)) {
    .backdrop-mask {
      mask: linear-gradient(transparent, black 25px);
      @apply pt-5;
    }
  }
</style>